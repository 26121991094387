import React from 'react';
import '../styles/CreateFormColumn.css';
function FormColumn(props){
    console.log("column", props.expectations);
    let expectationTiles;
    if(Array.isArray(props.expectations)){
       expectationTiles = props.expectations.map((expectation, index)=>{
        return (
          <li>{expectation}</li>
        )
      })
    } else {
     expectationTiles = Object.keys(props.expectations).map(
      (expectation, index) => {
        return (
           <li>{expectation}</li>
        );
      }
    );
    }
    

    return(
        <div className='create-form-column' style={{width:"100px"}}>
            <div className='create-form-column-heading-box'>
                <p style={{color: props.isPresent ? "#000":"#fe414d"}}>{props.nameOfColumn}</p>
            </div>
            {props.isPresent && 
                <div className='create-form-column-content-box'>
                    <ol>
                        {expectationTiles}
                    </ol>
                    {expectationTiles.length===0 && <h4 style={{textAlign:"center"}}>No Expectations/ProbeAreas Added</h4>}
                </div>
            }
            {!props.isPresent &&  
                <div className='create-form-column-content-box' style={{textAlign:"center"}}>
                    <h3 style={{color:"#fe414d"}}>This Variant is Not created</h3>
                </div>
            }
        </div>
    )
}
export default FormColumn;