import { useAuthenticator } from '@aws-amplify/ui-react';
import { TextField } from "@mui/material";
import "../styles/SectionSubSectionEditor.css"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import BlackButton from "./BlackButton";
import { useState } from "react";
import axios from "axios";
import Loader from "./Loader";
import { proxy } from "../proxy";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
function RoleAdder(){
    const { user} = useAuthenticator((context) => [context.user]);
    const [role,setrole]=useState('');
    const [isLoading,setIsLoading]=useState(false)
    const history = useHistory()
    async function  senddata(){
        // console.log(role)
        document.getElementById('section-submit').disabled=true;
        setIsLoading(true)
        axios.post(`${proxy}/api/role`,{"role":`${role}`}, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              'Authorization':user.signInUserSession.idToken.jwtToken,

              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {
                setIsLoading(false);
            //   console.log(res.data);
              alert("Role succesfully added");
              history.push('/roles')
          }).catch((error)=>{
            setIsLoading(false);
            
            alert("error")
            console.log(error)
          });
    }
    return(
        <div>
            {!isLoading && 
            <div>
                <div className="sections-header">
                    <div className="sections-title-header">
                        <p>Add Your Data here <ArrowDownwardIcon/> </p>
                    </div>
                </div>
                <div className="section-editor-components">
                    <div className="section-editor-component">
                        <div className="section-editor-component-title">
                            <p>Add Your Role</p>
                        </div>
                        <TextField fullWidth onChange={(e)=>setrole(e.target.value)} validate placeholder="Type Your Role"/>
                    </div>
                    <BlackButton id="section-submit" variant="contained" style={{marginTop:"10px"}} onClick={()=>{
                        document.getElementById('section-submit').disabled=true;
                        senddata()
                        document.getElementById('section-submit').disabled=false;
                    }}> Submit</BlackButton>
                </div>
            </div>}
            {isLoading && <Loader/>}
        </div>

    )
}
export default RoleAdder;