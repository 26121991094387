import { useSelector } from "react-redux"
import BlockKeyPointsReview from "./BlockKeyPointsReview"
import "../styles/Block.css"

function BlockReview(props){
    const sectionId=useSelector((store)=>{
     
        return(store.block.sectionId==="" ? "Not selected" : store.block.sectionId )
    })
    const subSectionId=useSelector((store)=>{
        return( store.block.subSectionId==="" ? "Not selected" : store.block.subSectionId )
    })
    const sectionName=useSelector((store)=>{
        return store.block.section
    })
    const subSectionName=useSelector((store)=>{
        return store.block.subSection
    })

    
    const keyPointsTiles=useSelector((store)=>{
        // console.log(store.adminform.rows)
        return (store.block.variants.map(({role,expectations},index) => {
                    return(
                        <BlockKeyPointsReview key={index} role={role} index={index} expectations={expectations}/>
                    )
            }))
    })
    const probeTiles=useSelector((store)=>{
        // console.log(store.adminform.rows)
        return (store.block.probeAreas.map((probeArea,index) => {
                    return(
                        <div className="block-probearea-tile" >
                    
                            <div className='block-probearea-text'> {probeArea}</div>
                        </div>
                    )
            }))
    });
    const recommendedProbeTiles = useSelector((store) => {
        // console.log(store.adminform.rows)
        return store.block.recommendedProbeAreas.map(
          (recommendedProbeArea, index) => {
            return (
              <div className="block-probearea-tile">
                <div className="block-probearea-text"> {recommendedProbeArea}</div>
              </div>
            );
          }
        );
      });
    return(
        <div className="block-review" style={{display:"flex",flexDirection:"column",gap:"30px"}}>
            <div className="block-selectors-row">
                <div className="block-selectors-col">
                    <p className='block-heading'>Section</p>
                    {sectionName}
                </div>
                <div  className="block-selectors-col">
                    <p className='block-heading'>Sub-Section</p>
                    {subSectionName}
                </div>
            </div>
            <div className="block-selectors-row">
        <div className="block-selectors-col">
          <h2 style={{ textAlign: "left", color: "grey", width: "100%" }}>
            Probe Areas
          </h2>
          <div className="block-probeareas-tiles">
            {probeTiles.length === 0 && <h3>No probe Areas are selected</h3>}
            {probeTiles}
          </div>
        </div>

        <div className="block-selectors-col">
          <h2 style={{ textAlign: "left", color: "grey", width: "100%" }}>
            Recommended Probe Areas
          </h2>
          <div className="block-probeareas-tiles">
            {recommendedProbeTiles.length === 0 && (
              <h3>No recommended probe Areas are selected</h3>
            )}
            {recommendedProbeTiles}
          </div>
        </div>
      </div>
            <h2 style={{textAlign:"center",color:"grey",padding:"0px"}}>Roles and Expectations</h2>
            <div className="block-keypoints-review" >
                 {keyPointsTiles.length===0 && <h3>No Roles are selected</h3>}

                {keyPointsTiles}
            </div>
        </div>
    )
}
export default BlockReview;