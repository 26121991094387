import React from "react";
import "../styles/CreateForm.css";
import { useSelector } from "react-redux";
import Loader from "./Loader";
import InterviewerFormRowView from "./InterviewerFormRowView";
import {
  InterviewerFormCandidateMarks,
  InterviewerFormCandidateTableView,
  InterviewerFormCandidatPhone,
} from "./InterviewerFormTables";
import { InterviewerProficiencyTable } from "./InterviewerFormTables";
import Modal from "react-modal";
import { Button } from "@mui/material";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { InterviewerFormPhone } from "./InterviewerFormPhone";

function InterviewerFormView(props) {
  const printRef = React.useRef();
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const caps = (str) => {
    return str.split(" ").map(capitalizeFirst).join(" ");
  };
  const handleDownloadPdf = async () => {
    const rest = await setCandidateDetailsOpen(true);
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", "a3", true);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const imgProperties = pdf.getImageProperties(data);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    let heightLeft = pdfHeight;
    let position = 0;
    pdf.addImage(data, "PNG", 0, position, pdfWidth, pdfHeight, "", "FAST");
    heightLeft = heightLeft - pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - pdfHeight;
      pdf.addPage();
      pdf.addImage(data, "PNG", 0, position, pdfWidth, pdfHeight, "", "FAST");
      heightLeft = heightLeft - pageHeight;
    }
    pdf.save(
      caps(props.data.candidateName).replace(" ", "_") +
        "-" +
        caps(props.data.interviewerName.replace(".", " ")).replace(" ", "_") +
        "-InterviewForm-" +
        props.data._id +
        ".pdf"
    );
    setCandidateDetailsOpen(false);
  };
  // console.log(props.data)
  //const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [modalIsOpenCandidate, setIsOpenCandidate] = React.useState(false);
  const [modalIsOpenMarks, setIsOpenMarks] = React.useState(false);
  const [showCandidateDetails, setCandidateDetailsOpen] = React.useState(false);
  let type;
  if (props.data.interviewType == "Domain") type = true;
  else type = false;
  function openModalCandidate() {
    setIsOpenCandidate(true);
  }
  function closeModalCandidate() {
    setIsOpenCandidate(false);
  }
  function openModalMarks() {
    setIsOpenMarks(true);
  }
  function closeModalMarks() {
    setIsOpenMarks(false);
  }

  const isLoading = useSelector((state) => {
    return state.interviewerForm.isLoading;
  });
  const templateName = props;
  let rows;
  if (type) {
    rows = props.data.blocks.map((blockData, index) => {
      //   console.log("rowdata",rowdata)
      return (
        <InterviewerFormRowView key={index} index={index} data={blockData} />
      );
    });
  }

  return (
    <div style={{}} ref={printRef}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "1px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ width: "50%", textAlign: "right" }}>
            <h3 style={{ color: "#fe414d" }}>
              {templateName === "" ? "Not Selected" : props.data.templateName}
            </h3>
          </div>

          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Button onClick={openModalCandidate}>Candidate Details</Button>
          <Button onClick={openModalMarks}>Marks Details</Button> */}
            <Button onClick={handleDownloadPdf}>Download as PDF</Button>
          </div>
        </div>
        <div
          style={{
            width: "87%",
            display: "flex",
            justifyContent: "space-between",
            margin: "auto",
          }}
        >
          <InterviewerFormCandidateTableView data={props.data} />
          {type && <InterviewerFormCandidateMarks data={props.data} />}
          {!type && <InterviewerFormCandidatPhone data={props.data} />}
        </div>
        <div className="interviewer-table-box">
          <Modal
            isOpen={modalIsOpenCandidate}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModalCandidate}
            styles={{ width: "40px", height: "fit-content", padding: "20px" }}
            contentLabel="Example Modal"
            className="Modal"
          >
            <InterviewerFormCandidateTableView data={props.data} />
          </Modal>
          <Modal
            isOpen={modalIsOpenMarks}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModalMarks}
            contentLabel="Example Modal"
            className="Modal"
          >
            <InterviewerProficiencyTable />
          </Modal>
        </div>
        {isLoading && <Loader />}
        {type && (
          <div
            className="form-wrapper"
            style={{ maxWidth: "100%", boxShadow: "0px 0px 0px 0px" }}
          >
            {!isLoading && (
              <div className="createform" style={{ margin: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "Column",
                    gap: "0px",
                  }}
                >
                  {rows}
                </div>
              </div>
            )}
          </div>
        )}
        {!type && (
          <InterviewerFormPhone data={props.data}></InterviewerFormPhone>
        )}
      </div>
    </div>
  );
}
export default InterviewerFormView;
