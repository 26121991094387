import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "./Reports.css";
import { motion } from "framer-motion";
import { proxy } from "../../proxy";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Zoom from "@mui/material/Zoom";
import { Tooltip } from "@mui/material";
import Loader from "../Loader";
import { useSelector } from "react-redux";

const TopContributors = (props) => {
  const [topContributors, setTopContributors] = useState([]);
  const { user } = useAuthenticator((context) => [context.user]);
  const profile= useSelector((state) => {
    return state.profile;
  });
  const presentProfile = user.attributes.profile;
  const ableToSwitch = user.attributes['custom:switch'];

  useEffect(() => {
    getTopContributors();
  }, [profile]);

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const caps = (str) => {
    return str.split(".").map(capitalizeFirst).join(" ");
  };

  const getTopContributors = async () => {
    if(ableToSwitch){
      try {
        const res = await axios.post(
          `${proxy}/api/topcontributors`,
          {
            startDate: props.startDate,
            endDate: props.endDate,
            profile: profile,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        );
        setTopContributors(res.data.result);
        // console.log(res.data.result);
      } catch (error) {
        console.log(error);
      }
    }else{
      try {
        const res = await axios.post(
          `${proxy}/api/topcontributors`,
          {
            startDate: props.startDate,
            endDate: props.endDate,
            profile: presentProfile,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        );
        setTopContributors(res.data.result);
        // console.log(res.data.result);
      } catch (error) {
        console.log(error);
      }
    } 
  };

  const sortAndDisplayTopThreeInterviewTaken = () => {
    const sortedInterviewTaken = [...topContributors].sort((a, b) => {
      return a.totalInterviewsCount < b.totalInterviewsCount ? 1 : -1;
    });
    const topThreeInterviewTakenResults = sortedInterviewTaken.slice(0, 3);

    return topThreeInterviewTakenResults.map((row, index) => (
      <TableRow
        key={row.interviewerName}
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
      >
        <TableCell component="th" scope="row">
          {caps(row.interviewerName)}
        </TableCell>
        <TableCell align="center">
          <Tooltip
            TransitionComponent={Zoom}
            title={`Qualified : ${
              row.Qualified ? row.Qualified : 0
            }, Disqualified : ${
              row.Disqualified ? row.Disqualified : 0
            }, Reiteration : ${row.Reiteration ? row.Reiteration : 0}`}
          >
            <span style={{ cursor: "pointer" }}>
              {row.totalInterviewsCount}
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
    ));
  };

  const sortAndDisplayTopThreeQualified = () => {
    const sortedQualified = [...topContributors].sort((a, b) => {
      return a.Qualified < b.Qualified ? 1 : -1;
    });
    const topThreeQualifiedResults = sortedQualified.slice(0, 3);

    return topThreeQualifiedResults.map((row, index) => (
      <TableRow
        key={row.interviewerName}
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
      >
        <TableCell component="th" scope="row">
          {caps(row.interviewerName)}
        </TableCell>
        <TableCell align="center">
          <span style={{ cursor: "pointer" }}>
            {row.Qualified ? row.Qualified : 0}
          </span>
        </TableCell>
      </TableRow>
    ));
  };

  const sortAndDisplayTopThreeDisqualified = () => {
    const sortedDisqualified = [...topContributors].sort((a, b) => {
      return a.Disqualified < b.Disqualified ? 1 : -1;
    });
    const topThreeDisqualified = sortedDisqualified.slice(0, 3);

    return topThreeDisqualified.map((row, index) => (
      <TableRow
        key={row.interviewerName}
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
      >
        <TableCell component="th" scope="row">
          {caps(row.interviewerName)}
        </TableCell>
        <TableCell align="center">
          <span style={{ cursor: "pointer" }}>
            {row.Disqualified ? row.Disqualified : 0}
          </span>
        </TableCell>
      </TableRow>
    ));
  };

  const sortAndDisplayTopThreeReiterations = () => {
    const sortedReiteration = [...topContributors].sort((a, b) => {
      return a.Reiteration < b.Reiteration ? 1 : -1;
    });
    const topThreeReiteration = sortedReiteration.slice(0, 3);

    return topThreeReiteration.map((row, index) => (
      <TableRow
        key={row.interviewerName}
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
      >
        <TableCell component="th" scope="row">
          {caps(row.interviewerName)}
        </TableCell>
        <TableCell align="center">
          <span style={{ cursor: "pointer" }}>
            {row.Reiteration ? row.Reiteration : 0}
          </span>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <React.Fragment>
      <div className="contributor-row">
        <motion.div
          whileHover={{
            scale: 1.02,
            boxShadow: "0px 0px 8px rgb(229, 228, 226)",
          }}
        >
          <div className="contributor-box" style={{ width: "90%" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "large",
                      }}
                    >
                      Interviewer Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "large",
                      }}
                      align="center"
                    >
                      Total Interviews Taken
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topContributors.length > 0 ? (
                    sortAndDisplayTopThreeInterviewTaken()
                  ) : (
                    <Loader />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </motion.div>

        <motion.div
          whileHover={{
            scale: 1.02,
            boxShadow: "0px 0px 8px rgb(229, 228, 226)",
          }}
        >
          <div className="contributor-box" style={{ width: "90%" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "large",
                      }}
                    >
                      Interviewer Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "large",
                      }}
                      align="center"
                    >
                      Total Qualified
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topContributors.length > 0 ? (
                    sortAndDisplayTopThreeQualified()
                  ) : (
                    <Loader />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </motion.div>

        <motion.div
          whileHover={{
            scale: 1.02,
            boxShadow: "0px 0px 8px rgb(229, 228, 226)",
          }}
        >
          <div className="contributor-box" style={{ width: "90%" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "large",
                      }}
                    >
                      Interviewer Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "large",
                      }}
                      align="center"
                    >
                      Total Disqualified
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topContributors.length > 0 ? (
                    sortAndDisplayTopThreeDisqualified()
                  ) : (
                    <Loader />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </motion.div>

        <motion.div
          whileHover={{
            scale: 1.02,
            boxShadow: "0px 0px 8px rgb(229, 228, 226)",
          }}
        >
          <div className="contributor-box" style={{ width: "90%" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "large",
                      }}
                    >
                      Interviewer Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "large",
                      }}
                      align="center"
                    >
                      Total Reiterations
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topContributors.length > 0 ? (
                    sortAndDisplayTopThreeReiterations()
                  ) : (
                    <Loader />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </motion.div>
      </div>
    </React.Fragment>
  );
};

export default TopContributors;
