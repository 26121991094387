import * as React from 'react';
import { useSelector } from "react-redux"
import "../styles/Block.css"
import { TextField } from '@mui/material';
import BlackButton from './BlackButton';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import { addProbeArea } from '../features/blockSlice';
import BlockProbeArea from './BlockProbeArea';

function BlockProbeAreas(props) {
    const dispatch=useDispatch();
    const [point, setpoint] = React.useState('');
    const handleChangeText = (event) => {
        setpoint( event.target.value);
        // console.log(point)

      };
    const pointTiles=useSelector((store)=>{
        // console.log(store.adminform.rows)
        return (store.block.probeAreas.map((text,index) => {
                return(
                    <BlockProbeArea key = {index} index={index}  point={text}/>
                )
            }))
    })
    return(
        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
            <div className='block-keypoints-div' style={{width:"100%"}}>
                <div className="block-keypoints-header">
                    <div className='block-keypoints-title'>Probe Areas</div>

                </div>
                <div className="block-probeareas-tiles" >
                    {pointTiles}
                </div>
                {pointTiles.length===0 && 
                <div className="block-keypoints-tiles">
                    <p style={{color:"grey",fontSize:"x-large",margin:"auto"}}>No Probe Areas Added</p>
                </div>
                }

                <TextField value={point} id="outlined-basic" label="Write Point" variant="outlined" style={{marginTop:"20px"}}fullWidth onChange={handleChangeText}
                        onKeyPress={(event) => {
                            // console.log(event.key)
                            if (event.key === 'Enter') {
                                // console.log(event.keyCode)
                                const variable= {"probearea":point}
                                setpoint("")
                                dispatch(addProbeArea(variable))
                            }
                        }}
                />
            </div>
            <BlackButton style={{width:"100px"}} variant="contained" startIcon={<AddIcon />} onClick={()=>{
                const variable= {"probearea":point}
                setpoint("")
                dispatch(addProbeArea(variable))
            }}>
            Add
            </BlackButton>
        
        </div>

  )

}

export default BlockProbeAreas;