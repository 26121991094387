import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { useAuthenticator } from "@aws-amplify/ui-react";

export function InterviewerFormCandidateTable() {
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const caps = (str) => {
    return str.split(".").map(capitalizeFirst).join(" ");
  };
  const { user } = useAuthenticator((context) => [context.user]);

  const candidateName = useSelector((state) => {
    return state.interviewerForm.candidateName;
  });
  const role = useSelector((state) => {
    return state.interviewerForm.role;
  });
  const interviewDate = useSelector((state) => {
    return state.interviewerForm.interviewDate;
  });

  const region = useSelector((state) => {
    return state.interviewerForm.region;
  })

  const employmentType = useSelector((state) => {
    return state.interviewerForm.employmentType;
  })
  function toShortDateFormat(date) {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const monthName = months[monthIndex];
    const year = date.getFullYear();

    return `${day} ${monthName}, ${year}`;
  }
  let today = new Date();
  return (
    <TableContainer component={Paper} style={{ width: "40%" }}>
      <Table sx={{ miWidth: 250 }} size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell>Interviewer Name</TableCell>
            <TableCell>{caps(user.username)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Candidate Name</TableCell>
            <TableCell>{candidateName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Interview Scheduled Date</TableCell>
            <TableCell>{toShortDateFormat(new Date(interviewDate))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Form Submitted Date</TableCell>
            <TableCell>{toShortDateFormat(today)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Evaluation Role</TableCell>
            <TableCell>{role}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Candidate Region</TableCell>
            <TableCell>{region ? region : "N/A"}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>Employment Type</TableCell>
            <TableCell>{employmentType ? employmentType : "N/A"}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Evaluation Role</TableCell>
            <TableCell>{region}</TableCell>
          </TableRow> */}
          {/* <TableRow>
                  <TableCell>Gender</TableCell>
                  <TableCell>{gender}</TableCell>
              </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export function InterviewerProficiencyTable() {
  return (
    <TableContainer component={Paper} style={{ width: "40%" }}>
      <Table sx={{ minWidth: 250 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Proficiency</TableCell>
            <TableCell align="left">Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center" component="th" scope="row">
              0
            </TableCell>
            <TableCell align="left" component="th" scope="row">
              {"No Experience or Knowledge"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" component="th" scope="row">
              1
            </TableCell>
            <TableCell align="left" component="th" scope="row">
              {"Beginner - Basic Theoretical Knowledge"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" component="th" scope="row">
              2
            </TableCell>
            <TableCell align="left" component="th" scope="row">
              {"Intermediate - Experience (At Least 6 months)"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" component="th" scope="row">
              3
            </TableCell>
            <TableCell align="left" component="th" scope="row">
              {"Proficient - Strong process design experience"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" component="th" scope="row">
              4
            </TableCell>
            <TableCell align="left" component="th" scope="row">
              {"Expert"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export function InterviewerFormCandidateTableView(props) {
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const caps = (str) => {
    return str.split(".").map(capitalizeFirst).join(" ");
  };

  return (
    <TableContainer component={Paper} style={{ width: "40%" }}>
      <Table sx={{ miWidth: 250 }} size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell>Interviewer Name</TableCell>
            <TableCell>{caps(props.data.interviewerName)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Candidate Name</TableCell>
            <TableCell>{props.data.candidateName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Interview Scheduled Date</TableCell>
            <TableCell>
              {new Date(props.data.interviewDate).toLocaleDateString("en-us", {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Form Submitted Date</TableCell>
            <TableCell>
              {new Date(props.data.timestamp).toLocaleDateString("en-us", {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Evaluated Role</TableCell>
            <TableCell>{props.data.role}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Candidate Region</TableCell>
            <TableCell>{props.data.region ? props.data.region : "N/A"}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>Employment Type</TableCell>
            <TableCell>{props.data.employmentType ? props.data.employmentType : "N/A"}</TableCell>
          </TableRow>
          {/* <TableRow>
              <TableCell> Gender</TableCell>
              <TableCell>{props.data.gender}</TableCell>
            </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function InterviewerFormCandidateMarks(props) {
  return (
    <TableContainer component={Paper} style={{ width: "40%" }}>
      <Table sx={{ miWidth: 250 }} size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell>Domain Total Score</TableCell>
            <TableCell
              style={{
                color:
                  props.data.status === "Qualified"
                    ? "green"
                    : props.data.status === "Reiteration"
                    ? "orange"
                    : "red",
              }}
            >
              {props.data.totalScore}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell
              style={{
                color:
                  props.data.status === "Qualified"
                    ? "green"
                    : props.data.status === "Reiteration"
                    ? "orange"
                    : "red",
              }}
            >
              {props.data.status}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Recommended Role</TableCell>
            <TableCell>{props.data.preferredRole}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Final Remarks</TableCell>
            <TableCell>{props.data.finalRemarks}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function InterviewerFormCandidatPhone(props) {
  return (
    <TableContainer component={Paper} style={{ width: "40%" }}>
      <Table sx={{ miWidth: 250 }} size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>{props.data.emailAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell
              style={{
                color:
                  props.data.status === "Qualified"
                    ? "green"
                    : props.data.status === "Reiteration"
                    ? "orange"
                    : "red",
              }}
            >
              {props.data.status}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Recommended Role</TableCell>
            <TableCell>{props.data.preferredRole}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
