import "../styles/profile.css";
import { useAuthenticator } from '@aws-amplify/ui-react';
import profileimage from "../images/profileimage.jpg"
function Profile(){
    const { user} = useAuthenticator((context) => [context.user]);
    console.log(user)
    return(
        <div className="profile-div">
            <img alt="not found" src={profileimage} style={{width:"100px",height:"100px",margin:"auto"}}/>
            <h3>UserName</h3>
            <h3 style={{color:"grey"}}>{user.username}</h3>
            <h3>Email</h3>
            <h3 style={{color:"grey"}}>{user.attributes.email}</h3>
            <h3>Profile Logged In:</h3>
            <h3 style={{ color: "grey" }}>{user.attributes.profile}</h3>
            <h3>Groups Present</h3>
            <div style={{display:"flex",gap:"20px"}}>{
                user.signInUserSession.idToken.payload['cognito:groups'].map((group)=>{
                    return(
                       <p style={{color:"grey",fontSize:"large"}}>{group}</p>
                    )
                })
            }</div>

        </div>
    )
}
export default Profile;