import "../styles/BlocksDisplayer.css"
import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemIcon, Tooltip } from '@mui/material';
import {Typography} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTemplateData } from "../features/templateSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuthenticator } from '@aws-amplify/ui-react';
import {proxy} from "../proxy.js";
import axios from "axios"
import { motion } from "framer-motion"
import { Button } from '@mui/material';
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import TemplateComment from "./TemplateComment";
import TemplateAddComment from "./TemplateAddComment";
import CommentIcon from '@mui/icons-material/Comment';
import DeleteIcon from '@mui/icons-material/Delete';

function FadeMenu(props) {
  React.useEffect(() => {
    window.scrollTo(0,0);
  },[])

    const { user } = useAuthenticator((context) => [context.user]);
    const dispatch=useDispatch();
    const history=useHistory();


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleTemplateDelete = () => {

      axios.delete(`${proxy}/api/template/${props.mongoid}`, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          'Authorization':user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }).then((res) => {
            //setIsLoading(false);
           console.log(res.data);
          alert("Template Deleted")
          window.location.reload();
      }).catch((error)=>{
       // setIsLoading(false);
        alert("error")
        console.log(error);
      });
      //setIsLoading(true);
    };
    return (
      <div style={{width:"100px",height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <Tooltip title=" Template Options">
              <MoreVertIcon
              id="fade-button"
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              className="icon-hover"
              fontSize="large"
            >
            </MoreVertIcon>
        </Tooltip>
  
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
        <Link to={`/template/${props.mongoid}`} style={{textDecoration:"none",color:"grey"}}>
              <MenuItem onClick={handleClose}>
                  <ListItemIcon >
                      <RemoveRedEyeIcon fontSize="small" />
                  </ListItemIcon>
              <Typography variant="inherit">View</Typography>
              </MenuItem>
          </Link>

        <MenuItem onClick={()=>{
        dispatch(getTemplateData({"id":props.mongoid,"jwtToken":user.signInUserSession.idToken.jwtToken}))
        history.push(`/addtemplate/${props.mongoid}`)}}>
            <ListItemIcon>
                <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Edit</Typography>
        </MenuItem>

        <MenuItem onClick={handleTemplateDelete}>
            <ListItemIcon>
                <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}


function TemplateDisplay(props) {

    const [approveBtnDisabled, setapproveBtnDisabled] = useState(false);
    const [unapproveBtnDisabled, setUnapproveBtnDisabled] = useState(true);

    const handleApprove = () => {
      setapproveBtnDisabled(true);
      axios.post(`${proxy}/api/status`,{"templateId":props.mongoid,"username":user.username,"status":"approve"}, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          'Authorization':user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }).then((res) => {
      //  setisLoading(false);
        console.log(res.data.result);
        setapproveBtnDisabled(false);
        window.location.reload(); 
      }).catch((error)=>{
      //  setisLoading(false);
      console.log(error);
      setapproveBtnDisabled(false);

    });
  };

  const handleUnApprove = () => {
    setUnapproveBtnDisabled(true);
    axios.post(`${proxy}/api/status`,{"templateId":props.mongoid,"username":user.username,"status":"unapprove"}, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        'Authorization':user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    }).then((res) => {
    //  setisLoading(false);
      console.log(res.data.result);
      window.location.reload();
      setUnapproveBtnDisabled(false);

    }).catch((error)=>{
      //  setisLoading(false);
      console.log(error);
      setUnapproveBtnDisabled(false);

    });
  };
  const { user } = useAuthenticator((context) => [context.user]);
  const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

    
    const [open, setOpen] = React.useState(false);
    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => setOpen(false);
    const [toDisplay,setToDisplay]=useState(false);

  return  (
    <div style={{width:"70%"}}>
      <motion.div whileHover={{scale:1.02}}>
      <div className="subsection-div"  style={{width:"100%",height:"100px",display:"flex",justifyContent:"space-between",alignItems:"center",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",background:"white"
    }} >
        <div className="subsection-title" style={{fontSize:"large",fontWeight:"bold"}}>
            {props.templateName}{!props.approvalStatus && <span> ( Yet to be Approved )</span>}
        </div>
        <div className="subsection-icons" style={{alignItems:"center"}}>
            {!props.approvalStatus && 
            <MenuItem onClick={handleApprove} disabled={approveBtnDisabled} className="status-div">
              <ListItemIcon style={{color:"red"}} >
                <CheckCircleIcon fontSize="small" />
              </ListItemIcon>
              <Tooltip title="Approve">
              <Typography variant="inherit" >Click to Approve</Typography>
              </Tooltip>
            </MenuItem>
          }

          {props.approvalStatus && 
            <MenuItem onClick={handleUnApprove} disabled={unapproveBtnDisabled} className="status-div">
              <ListItemIcon style={{color:"green"}}  >
                <CheckCircleIcon fontSize="small" />
              </ListItemIcon>
              <Tooltip title="Unapprove / Decline">
              <Typography variant="inherit" >Approved</Typography>
              </Tooltip>
          </MenuItem>}
          {/*} <Tooltip title="Add Comment" comment>
          //   <Button onClick={handleModalOpen}><DescriptionIcon style={{color:"red"}}/></Button>
          // </Tooltip>*/}
          <Tooltip title="Add/View Comments" Comments>
            <Button onClick={()=>setToDisplay(!toDisplay)}><CommentIcon style={{color:"#33bfff"}} /></Button>
          </Tooltip>
          <Modal
              open={open}
              onClose={handleModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box sx={style}>
              <Typography style={{textAlign:"center"}} id="modal-modal-title" variant="h6" component="h2">
                  Add Comments
              </Typography>
              <TemplateAddComment/>
              </Box>
          </Modal>
          <FadeMenu mongoid={props.mongoid} approvalStatus={props.approvalStatus}/>    
        </div>
      </div>
      {toDisplay && 
        <motion.div initial={{ y: -10 }} animate={{ y: 0 }} transition={{ type: "spring", stiffness: 100 }} className="template-comments">
            <div style={{width:"100%",fontSize:"x-large",fontWeight:"bolder",textAlign:"center",color:"grey"}}>
                Comments
            </div>
            {props.comments && props.comments.map(({username,comment,_id,postedDate})=>{
                return(<TemplateComment key={_id} username={username} comment={comment} commentId={_id} postedDate={postedDate} templateId={props.mongoid}/>)
            })}
            <div style={{height:"fit-content"}}>
              <TemplateAddComment templateId={props.mongoid}/>
            </div> 
        </motion.div>}
      </motion.div>
    </div>

      

  )
}
export default TemplateDisplay;
// <div className="status-div">
// <motion.div className="templateCircle" style={{background:props.approvedUsers.includes(user.username) ?"green":"red"}}
// ></motion.div>
// <p>
// {props.approvedUsers.includes(user.username) ? "Approved":"Unapproved"}
// </p>
// </div>