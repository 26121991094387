import unauthorised from "../images/unauthorisedimage.webp"

function UnAuthorised(){
    return(
        <div style={{margin:"auto",marginTop:"100px",width:"100%",textAlign:"center"}}>
            <img alt="notfound" src={unauthorised} style={{maxWidth:"350px",minWidth:"50px",height:"250px"}}/>
            <h2>You Are <b style={{color:"red"}}>Not</b> Authourised To View This Page</h2>
        </div>
    )
}
export default UnAuthorised;