import { Checkbox, FormControlLabel, FormGroup, TableCell } from '@mui/material';
import React, { useState, useEffect } from 'react';

const CheckboxGroup = (props) => {
    const [checked, setChecked] = useState([]);
    
    const cloudTypes = ['AWS', 'Azure', 'GCP'];

    const handleCheckbox = (cloudType) => {
        const updatedChecked = checked.includes(cloudType)
            ? checked.filter(item => item !== cloudType)
            : [...checked, cloudType];
        setChecked(updatedChecked);
        props.handleFilters(updatedChecked);
    }

    useEffect(() => {
        // Apply expertise filters to the table...
    }, [checked]);

    return (
        <React.Fragment>
            {cloudTypes.map((value) => (
                <TableCell align="center" key={value}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={value}
                                    onChange={() => handleCheckbox(value)}
                                    checked={checked.includes(value)}
                                />
                            }
                            label={value}
                        />
                    </FormGroup>
                </TableCell>
            ))}
        </React.Fragment>
    );
}

export default CheckboxGroup;
