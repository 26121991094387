import { useAuthenticator } from '@aws-amplify/ui-react';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@aws-amplify/ui-react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import axios from "axios"
import {proxy} from "../proxy"
import { useEffect } from 'react';
import { tidy, distinct,filter } from '@tidyjs/tidy'
import { Tooltip, Typography } from '@mui/material';
import InterviewerFormView from './InterviewerFormView';
import Loader from './Loader';
import SearchIcon from '@mui/icons-material/Search';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TablePagination from '@mui/material/TablePagination';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from "react-redux";

function AdminSearchForm(){

    let date = new Date();
    date.setDate(date.getDate() - 10);

    const { user} = useAuthenticator((context) => [context.user]);
    const [candidateName,setCandidateName]=React.useState(null);
    const [interviewerName,setInterviewerName]=React.useState(null);
    const [role,setRole]=React.useState(null);
    const [isLoading,setisLoading]=React.useState(false)
    const [formData,setFormData]=React.useState([]);
    const [data,setData]=React.useState([])
    const [displayingData,setDisplayingData]=React.useState([])
    const [candidates,setCandidates]=React.useState([]);
    const [interviewers,setInterviewers]=React.useState([]);
    const [roles,setRoles]=React.useState([]);
    const [display,setDisplay]=React.useState(false)
    const [showHeader,setShowHeader]=React.useState(false);
    const [status,setStatus]=React.useState(null)
    const [statuses,setstatuses]=React.useState(["DisQualified","Reiteration","Qualified"]);
    const [startValue, setStartValue] = React.useState(date);
    const [endValue, setEndValue] = React.useState(new Date());
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [getExcelData, setExcelData] = React.useState([]);
    const interviewHistory = useHistory();
    const candidateHistory = useHistory();
    const [order,setOrder] = React.useState("ASC");
    const profile= useSelector((state) => {
        return state.profile;
      });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function initialiseArrays(data){
        let candidates=[];
        let interviewers=[];
        let roles=[];
        if(data!==null && data !==undefined){
            data.map(({candidateName,interviewerName,role})=>{
                candidates.push(candidateName);
                interviewers.push(interviewerName);
                roles.push(role);
                return 1;
            })
        }
 
        setCandidates(tidy(candidates, distinct([])));
        setInterviewers(tidy(interviewers, distinct([])));
        setRoles(tidy(roles, distinct([])));
        return true;

    }
    useEffect(() => {
        window.scrollTo(0, 0)
        // setisLoading(!isLoading);
        // console.log("start",isLoading)
        axios.get(`${proxy}/api/forms`, {
            withCredentials: true,
            headers: {
              'Authorization':user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {
            
            //   console.log(res.data);
            //   const {section,subSections}=res.data
              setData(res.data.result);
              initialiseArrays(res.data.result);
              setisLoading(false);
          }).catch((error)=>{
            setisLoading(false);
            // console.log(error)
          });

          
 // eslint-disable-next-line
      }, []);
    
      const downloadExcel = async()=>{
        try {
            const  res = await axios.post(`${proxy}/api/admindownloadexcel`,{ "startDate" : startValue,"endDate" : endValue, "profile": profile, }, {
                withCredentials: true,
                headers: {
                    'Authorization':user.signInUserSession.idToken.jwtToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true,
                },
            }).then((res) => {
                console.log(res.data.result)
                setExcelData(res.data.result);
                // console.log(res.data.result)
                convertToExcel(res.data.result)
                alert('Download Status : Excel Downloaded Successfully')
            })
            
        } catch (error) {
            alert("Download Status : Error Downloading")
        }
    }

    // const getMismatchReport =()=>{
    //     try {
    //         const  res = axios.post(`${proxy}/api/exceldownload`, { "startDate" : startValue,"endDate" : endValue },{
    //             withCredentials: true,
    //             headers: {
    //                 'Authorization':user.signInUserSession.idToken.jwtToken,
    //                 Accept: "application/json",
    //                 "Content-Type": "application/json",
    //                 "Access-Control-Allow-Credentials": true,
    //             },
    //         });
    //         //console.log(res.data.result)
    //         alert("Excel Downloaded Successfully")
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const convertToExcel = (excelData) => {
            const ws = XLSX.utils.json_to_sheet(excelData);
            const wb = { Sheets:{ data:ws }, SheetNames:[ "data" ] }
            const excelBuffer = XLSX.write(wb, { bookType:"xlsx", type:"array" })
            const data = new Blob([excelBuffer], { type:"xlsx" })
            FileSaver.saveAs(data, "Evaluation_Dump"+".xlsx")
    }


    const handleChangeCandidateName=(event,newvalue)=>{
        // console.log(newvalue);
        setCandidateName(newvalue)
    }
    const handleChangeInterviewerName=(event,newvalue)=>{
        // console.log(newvalue);
        setInterviewerName(newvalue);
    }
    const handleChangeRole=(event,newvalue)=>{
        // console.log(newvalue);
        setRole(newvalue);
    }
    const handleChangeStatus=(event,newvalue)=>{
        // console.log(newvalue);
        setStatus(newvalue);
    }
    function processDate(date){
        let parts = date.split("/");
        return new Date(parts[2], parts[1] - 1, parts[0])
    }

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
    const caps = str => {
        return str.split(' ').map(capitalizeFirst).join(' ');
      };
    
    const setdisplayabledata=()=>{
        let datatobedisplayed = data;
        // console.log(candidateName,interviewerName,role)
        if(candidateName!==null && candidateName!=="" && candidateName!==undefined ){
            datatobedisplayed=tidy(data, filter((d) => d.candidateName === candidateName))
        }
        if(interviewerName!==null && interviewerName!=="" && interviewerName!==undefined ){
            datatobedisplayed=tidy(datatobedisplayed, filter((d) => d.interviewerName  === interviewerName))
        }
        if(role!==null && role!=="" && role!==undefined ){
            datatobedisplayed=tidy(datatobedisplayed, filter((d) => d.role  === role))
        }
        if(status!==null && status!=="" && status!==undefined ){
            if(status==="DisQualified"){
                datatobedisplayed=tidy(datatobedisplayed, filter((d) => d.status ==="Disqualified"));
            } 
            else if(status==="Reiteration"){
                datatobedisplayed=tidy(datatobedisplayed, filter((d) => d.status ==="Reiteration"));
            }
            else if(status==="Qualified"){
                datatobedisplayed=tidy(datatobedisplayed, filter((d) => d.status ==="Qualified"));
            }
        }
       
        if(startValue!==null && startValue!==undefined && endValue!==null && endValue!==undefined){
            const min = new Date(startValue).toLocaleDateString('en-GB');
            const max = new Date(endValue).toLocaleDateString('en-GB');    
            datatobedisplayed=tidy(datatobedisplayed, filter((d) => processDate(new Date(d.interviewDate).toLocaleDateString('en-GB')) >= processDate(min) && processDate(new Date(d.interviewDate).toLocaleDateString('en-GB')) <= processDate(max)))
        }
        setShowHeader(true);
        setDisplayingData(datatobedisplayed);
        initialiseArrays(data);
        setDisplay(false);
        setPage(0);

    }

    const sortingString =(col) => {
        if (order === "ASC") {
            const sorted = [...data].sort((a,b) => 
            a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
            );
            setDisplayingData(sorted);
            setOrder("DSC");
        }

        if (order === "DSC") {
            const sorted = [...data].sort((a,b) => 
            a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
            );
            setDisplayingData(sorted);
            setOrder("ASC");
        }
    }

    const sortingNumber =(col) => {
        if (order === "ASC") {
            const sorted = [...data].sort((a,b) => 
            a[col] > b[col] ? 1 : -1
            );
            setDisplayingData(sorted);
            setOrder("DSC");
        }

        if (order === "DSC") {
            const sorted = [...data].sort((a,b) => 
            a[col] < b[col] ? 1 : -1
            );
            setDisplayingData(sorted);
            setOrder("ASC");
        }
    }

    function changeTimezone(date){
        const d = new Date(date);
        d.setHours(d.getHours() + 5);
        d.setMinutes(d.getMinutes() + 30)

        return d
    }

    return(
        <div>
            <div className="sections-header" style={{height:"50px",background:"#fe414d"}}>
                <div className="sections-title-header">
                    <p style={{color:"white"}}>Search Forms </p>
                </div>
            </div>
            {!isLoading  && 
            <TableContainer component={Paper} sx={{ minWidth: 650,maxWidth:"100%",margin:"auto"}}>
                    <Table sx={{ minWidth: 650, maxWidth:'100%'}} size="small" aria-label="a dense table">
                        <TableHead style={{width:'100%'}}>
                            <TableRow>
                                <TableCell align="left">
                                    <Autocomplete disablePortal id="combo-box-demo" options={candidates} sx={{ width: 200 }} 
                                    renderInput={(params) => <TextField {...params} label="Candidate" />}
                                    onChange={handleChangeCandidateName}
                                    /> 
                                </TableCell>
                                <TableCell align="left">
                                    <Autocomplete disablePortal id="combo-box-demo" options={interviewers} sx={{ width: 200  }} 
                                        renderInput={(params) => <TextField {...params} label="Interviewer" />}
                                        onChange={handleChangeInterviewerName}
                                        /> 
                                </TableCell>
                                <TableCell align="left">
                                    <Autocomplete disablePortal id="combo-box-demo" options={roles} sx={{ width: 130 }} 
                                        renderInput={(params) => <TextField {...params} label="Role" />}
                                        onChange={handleChangeRole}
                                        /> 
                                </TableCell>
                                <TableCell align="left">
                                    <Autocomplete disablePortal id="combo-box-demo" options={statuses} sx={{ width: 120 }} 
                                        renderInput={(params) => <TextField {...params} label="Status" />}
                                        onChange={handleChangeStatus}
                                        /> 
                                </TableCell>
                                
                                <TableCell align="left" style={{color:"black"}}>
                                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            inputFormat='DD/MM/YYYY'
                                            maxDate={new Date()}
                                            label="Start Date"
                                            renderInput={(params) => <TextField {...params} name="startDate" sx={{width:150}}/>}
                                            value={startValue}
                                            onChange={(newValue) => {
                                                setStartValue(newValue);
                                            }}
                                            /> 
                                      </LocalizationProvider> 
                                </TableCell>
                                <TableCell align="left" style={{color:"black"}}>
                                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                inputFormat='DD/MM/YYYY'
                                                minDate={startValue}
                                                maxDate={new Date()}
                                                label="End Date"
                                                renderInput={(params) => <TextField {...params} name="endDate" sx={{width:150}}/>}
                                                value={endValue}
                                                onChange={(newValue) => {
                                                    setEndValue(newValue);
                                                }}
                                            />
                                      </LocalizationProvider> 
                                </TableCell>
                                <TableCell align="left">
                                    <Tooltip title="Search">
                                        <Button variant="outlined" size="medium" onClick={setdisplayabledata} >
                                        <SearchIcon/>&nbsp;Search
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="left">
                                    <Tooltip title="Download Excel">
                                        <Button 
                                            variant="outlined" 
                                            onClick={downloadExcel} >
                                            <DownloadIcon/>
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showHeader &&  
                                <TableRow
                                    key={-1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0,fontSize:"large"} }}
                                    >
                                        <TableCell 
                                            align="left" 
                                            style={{fontWeight:"bold"}}>
                                                Candidate Name&nbsp;
                                                {/* {order === 'ASC' &&
                                                <Tooltip title="Sort Ascending">
                                                <ArrowUpwardIcon style={{cursor:"pointer"}} onClick={()=> sortingString("candidateName")} />  
                                                </Tooltip>
                                                }
                                                {order === 'DSC' && 
                                                <Tooltip title="Sort Descending">
                                                <ArrowDownwardIcon style={{cursor:"pointer"}} onClick={()=> sortingString("candidateName")}/>
                                                </Tooltip>
                                                } */}
                                                </TableCell>
                                        <TableCell align="left" style={{fontWeight:"bold"}}>Interviewer Name</TableCell>
                                        <TableCell align="left" style={{fontWeight:"bold"}}>Evaluated Role</TableCell>
                                        <TableCell 
                                            align="left" 
                                            style={{fontWeight:"bold"}}>
                                                Interview Date&nbsp;
                                                {order === 'ASC' &&
                                                <Tooltip title="Sort Ascending">
                                                <ArrowUpwardIcon style={{cursor:"pointer"}} onClick={()=> sortingNumber("interviewDate")} />  
                                                </Tooltip>
                                                }
                                                {order === 'DSC' && 
                                                <Tooltip title="Sort Descending">
                                                <ArrowDownwardIcon style={{cursor:"pointer"}} onClick={()=> sortingNumber("interviewDate")}/>
                                                </Tooltip>
                                                }
                                                </TableCell>
                                        <TableCell align="left" style={{fontWeight:"bold"}}>Status</TableCell>
                                        <TableCell 
                                            align="left" 
                                            style={{fontWeight:"bold"}}>
                                                Domain Score&nbsp;
                                                {order === 'ASC' &&
                                                <Tooltip title="Sort Ascending">
                                                <ArrowUpwardIcon style={{cursor:"pointer"}} onClick={()=> sortingNumber("totalScore")} />  
                                                </Tooltip>
                                                }
                                                {order === 'DSC' && 
                                                <Tooltip title="Sort Descending">
                                                <ArrowDownwardIcon style={{cursor:"pointer"}} onClick={()=> sortingNumber("totalScore")}/>
                                                </Tooltip>
                                                }
                                                </TableCell>
                                        <TableCell align="left" style={{fontWeight:"bold"}}>View</TableCell>
                                        <TableCell></TableCell>

                                </TableRow>
                            }

                            {displayingData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((data,index) => (   
                                <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                
                                    <TableCell 
                                        align="left">
                                            {data.candidateName}
                                            <Typography className={{}} variant="body2" component="span">
                                                        <Tooltip title="View Candidate Profile">
                                                        <OpenInNewIcon
                                                            style={{color:'red', marginLeft:'10px',cursor:'pointer'}} 
                                                            fontSize="small" 
                                                            onClick={()=> candidateHistory.push('/reports/candidate', 
                                                            { candidateName : data.candidateName, scheduledDate: data.timestamp })}/>
                                                        </Tooltip> 
                                            </Typography>
                                            </TableCell>
                                    <TableCell 
                                        align="left" style={{}}>
                                            {`${caps((data.interviewerName).replace("."," "))}`}
                                            <Typography className={{}} variant="body2" component="span">
                                                        <Tooltip title="View Interviewer Profile">
                                                        <OpenInNewIcon
                                                            style={{color:'red', marginLeft:'10px',cursor:'pointer'}} 
                                                            fontSize="small" 
                                                            onClick={()=> interviewHistory.push('/reports/interviewpanel', 
                                                            { interviewerName : data.interviewerName })}/>
                                                        </Tooltip> 
                                            </Typography>
                                            </TableCell>
                                    <TableCell align="left">{data.role}</TableCell>
                                    <TableCell align="left">{new Date(data.interviewDate).toDateString()}</TableCell>
                                    <TableCell  align="left" style={{color:data.status==="Qualified" ? "green": data.status==="Reiteration" ? "orange":"Red"}} >
                                        { data.status}  
                                    </TableCell>
                                    <TableCell  align="left" style={{color:data.status==="Qualified" ? "green": data.status==="Reiteration" ? "orange":"Red"}} >
                                        { data.totalScore}  
                                    </TableCell>
                                    <TableCell align="left" style={{cursor:"pointer"}}>
                                        <Tooltip title="View File">
                                            <RemoveRedEyeIcon onClick={()=>{
                                                setDisplay(false);
                                                setisLoading(true);
                                                axios.post(`${proxy}/api/forms`,{"id":data.formId}, {
                                                    withCredentials: true,
                                                    headers: {
                                                    Accept: "application/json",
                                                    'Authorization':user.signInUserSession.idToken.jwtToken,
                                                    "Content-Type": "application/json",
                                                    "Access-Control-Allow-Credentials": true,
                                                    },
                                                }).then((res) => {
                                                    setisLoading(false);
                                                    
                                                    //   console.log(res.data);
                                                    //   const {section,subSections}=res.data
                                                    setFormData(res.data.results)
                                                    setDisplay(true);
                                                    setisLoading(false);

                                                }).catch((error)=>{
                                                    setisLoading(false);
                                                    // console.log(error);
                                                });
                                                setStatus(null);
                                                setCandidateName(null)
                                                setInterviewerName(null)
                                                setRole(null)
                                                setstatuses(["DisQualified","Reiteration","Qualified"])
                                                setShowHeader(false);
                                                setDisplayingData([]);
                                            }}/>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell></TableCell>
    
                                    {/* <TableCell>
                                        {data.interviewerName===user.username && <Link to={`/interviewform/${data.formId}`} style={{textDecoration:"none",color:"#000"}}>
                                            <Tooltip title="Edit File">
                                                <EditIcon/>
                                            </Tooltip>
                                        </Link>}
                                        {data.interviewerName!==user.username && 
                                            <p style={{color:"red",fontSize:"10px"}}>Cannot Edit</p>
                                        }
                                    </TableCell> */}
                                
                                </TableRow>
                                
                            ))}
                            
                        </TableBody>
                    </Table>
                    {displayingData.length===0 && 
                    <div style={{textAlign:"center",fontSize:"24px",width:"100%"}}>
                                {display ? "See the Form Below":"No Results Found"}
                    </div>
                    }
                    <TablePagination
                        rowsPerPageOptions={[5,10]}
                        component="div"
                        count={displayingData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
            </TableContainer>}
            {isLoading && <Loader/>}
            {display && <InterviewerFormView data={formData}/>}

        </div>
    )
}

export default AdminSearchForm;