import * as React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import './Modal.css'
import {  Checkbox, Fab, FormControl, InputLabel, MenuItem, Select, TextareaAutosize, TextField, Tooltip, Typography } from '@mui/material';
import BlackButton from '../BlackButton';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { proxy } from '../../proxy';
import { useState } from 'react';
import axios from 'axios';
import { useAuthenticator } from '@aws-amplify/ui-react';
import CloseIcon from '@mui/icons-material/Close';
// import { useSelector } from 'react-redux';
import ReactConfetti from './ReactConfetti';

const backdrop = {
    visible : { opacity: 1 },
    hidden : { opacity: 0 }
}

const modal = {
    hidden: {
        y: "-100vh",
        opacity: 0
    },
    visible: {
        y: "70px",
        opacity: 1,
        transition: { delay: 0.5 }
    }
}

const Modal = ({showModal, setShowModal,showConfetti,setShowConfetti,scoreData, formId, currentRole}) => {
    // const profile= useSelector((state) => {
    //     return state.profile;
    //   });
    const { user} = useAuthenticator((context) => [context.user]);
    const [roleData,setRoleData] = useState([]);
    const [role,setRole]=useState();
    const history = useHistory();
    const [checked, setChecked] = useState(false);
    const [changed, setChanged] = useState(false);
    const [finalRemarks,setFinalRemarks]=useState("")
    const [status,setStatus]= useState("Disqualified");
    const [parameter,setParameter] = useState("");
    const handleModal = () => {
        submitFinalDetails();
        setShowModal(false);
        alert("Interview Form Saved Successfully !!")
        history.push("/")
    
    }
    const handleChangeRole=(event)=>{
        setRole(event.target.value)
    }
    const handleChangeCheckbox = (event) => {
        const a= parameter;
        const b= parameter.trim();
        const c="39";
        console.log(parameter);
        console.log(typeof a);
        console.log(typeof b);
        console.log(b);
        console.log(typeof c);
        console.log(Number(a));
        console.log(Number(b));
        console.log(Number(c));
        console.log(typeof parseInt(parameter,10));
        console.log(typeof scoreData[0].totalScore);
        setChecked(event.target.checked);
    };

    
    const handleChangeStatus = (event) => {
        setChanged(event.target.checked);
        if(event.target.checked){
            setStatus("Qualified");
        }
        else
        {
            setStatus("Disqualified");
        }
    };

    useEffect(()=> {
        axios.get(`${proxy}/api/role`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              'Authorization':user.signInUserSession.idToken.jwtToken,
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => { 
                console.log("Role Data:",res.data.result) 
              setRoleData(res.data.result);
              setRole(currentRole);
          }).catch((error)=>{
            console.log(error)
          });

          axios.get(`${proxy}/api/parameterStore`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              'Authorization':user.signInUserSession.idToken.jwtToken,
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {  
              console.log("Parameter Store Value:",res.data.result);
              setParameter(res.data.result.substring(1,3));
          }).catch((error)=>{
            console.log(error)
          });

          if (showConfetti) {
            const confettiTimer = setTimeout(() => {
              setShowConfetti(false);
            }, 8000); // 8 seconds

            return () => {
                clearTimeout(confettiTimer);
              };
            }
        
    },[showConfetti])

    // useEffect(()=> {
        
        
    // },[])


    const submitFinalDetails = async() => {
        try {
            let a;
            if(checked)
            {
                a="Reiteration"
            }
            else if(scoreData[0].totalScore >= 70)
            {
                a="Qualified"
            }
            else if(scoreData[0].totalScore < Number(parameter))
            {
                a="Disqualified"
            }
            else
            {
                a=status
            }
          const response = await axios.put(`${proxy}/api/formblock`, 
            { "formId": formId,
            "totalScore":scoreData.totalScore, 
            "finalRemarks":finalRemarks , 
            "preferredRole":role, 
            "status":a,
            "changed":changed},
            {
              withCredentials: true,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization':user.signInUserSession.idToken.jwtToken,
                "Access-Control-Allow-Credentials": true,
              },
            });
          
          // convertToExcel(data)
          console.log(response.data)
          return response.data
      } catch (err) {        
          console.log(err);
      }
      }

    return (
        <AnimatePresence exitBeforeEnter>
            {showModal && (
                <motion.div className='backdrop'
                    variants={backdrop}
                    initial="hidden"
                    animate="visible" 
                    exit="hidden" 
                >
                    <motion.div className='modal' 
                    variants={modal}
                    >
                        {scoreData && scoreData.map((data,index)=> {
                            return (
                        <React.Fragment>
                            {showConfetti && data.totalScore >= 70 && <ReactConfetti run ={showConfetti}/>}
                        <div className='submit-box'>
                            <Tooltip title="Close">
                                <CloseIcon style={{color:"#FE414D",position:'absolute',right:'15px', top:'15px', cursor:'pointer'}} onClick={() => {setShowModal(false)}}/>
                            </Tooltip>
                            <div className="submit-wrapper">
                                <h2 key={index}> Total Score: <b style={{color:data.totalScore >= 70 ? "green":"red"}}>{data.totalScore}</b></h2>
                                <div className='submit-checkbox'>
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChangeCheckbox}
                                    inputProps={{ 'aria-label': 'controlled' }}/>
                                    <p><b>Second Round Required ?</b></p>
                                </div>
                                
                                <div className='submit-role'>
                                     <Typography style={{fontWeight:'bold'}}>Recommended Role</Typography>
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
                                        <Select
                                            style={{width:'220%'}}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={role}
                                            label="Select Role"
                                            onChange={handleChangeRole}>
                                            {
                                              roleData.map(({_id,role})=>{
                                                    return(
                                                        <MenuItem key={_id} value={role}>{role}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>               
                                </div>

                                <div className='submit-remarks'>
                                    <Typography align='left' style={{fontWeight:'bold'}}>Final Remarks</Typography>
                                    <TextField fullWidth
                                        id="outlined-multiline-static"
                                        multiline
                                        rows={3}
                                        value={finalRemarks}
                                        onChange={(event)=>{setFinalRemarks(event.target.value)}}
                                        placeholder="Enter Final Remarks"
                                    />                 
                                </div>
                                {checked &&
                                <div className='submit-status'>
                                    <Typography style={{fontWeight:'bold'}}>Status</Typography>
                                    <div className="finalremarks" style={{color:checked ? "orange":data.totalScore >= 70 ? "green":"red",justifyContent:"flex-start"}}>
                                    {checked ? "Reiteration":data.totalScore>=70 ? "Qualified":"Disqualified"}  
                                    </div>     
                                </div>
                                }
                                {!checked && data.totalScore < Number(parameter) &&
                                <div className='submit-status'>
                                    <Typography style={{fontWeight:'bold'}}>Status</Typography>
                                    <div className="finalremarks" style={{color:checked ? "orange":data.totalScore >= 70 ? "green":"red",justifyContent:"flex-start"}}>
                                    {checked ? "Reiteration":data.totalScore>=70 ? "Qualified":"Disqualified"}  
                                    </div>     
                                </div>
                                }
                                {!checked && data.totalScore>=70 &&
                                <div className='submit-status'>
                                    <Typography style={{fontWeight:'bold'}}>Status</Typography>
                                    <div className="finalremarks" style={{color:checked ? "orange":data.totalScore >= 70 ? "green":"red",justifyContent:"flex-start"}}>
                                    {checked ? "Reiteration":data.totalScore>=70 ? "Qualified":"Disqualified"}  
                                    </div>     
                                </div>
                                }
                                {!checked && data.totalScore >= Number(parameter) && data.totalScore < 70 &&
                                <div className='submit-status'>
                                <Typography style={{fontWeight:'bold'}}>Status</Typography>
                                <div className="finalremarks" style={{color:changed ? "green":"red",justifyContent:"flex-start"}}>
                                    {changed ? "Qualified":"Disqualified"}  
                                    </div>   
                                    <div className='submit-checkbox'>
                                    <Checkbox
                                        checked={changed}
                                        onChange={handleChangeStatus}
                                        inputProps={{ 'aria-label': 'controlled' }}/>
                                        <p><b>Override current status</b></p>
                                    </div>
                                {/* <Select
                                    name="status"
                                    label="Choose Status"
                                    value={status}
                                    onChange={(event)=>{setStatus(event.target.value)}}
                                    >
                                        <MenuItem value="Qualified">
                                        Qualified
                                        </MenuItem>
                                        <MenuItem value="Disqualified">
                                        Disqualified
                                        </MenuItem>
                                        
                                    </Select> */}
                                </div> 
                                }    


                                <div className='submit-button'>
                                 <BlackButton variant='contained' disabled={finalRemarks==="" || role===""} onClick={handleModal}>Submit</BlackButton>
                                </div>
                            </div>
                        </div>
                        </React.Fragment>
                            )
                        })}
                        
                    </motion.div>
                </motion.div>

            )}

        </AnimatePresence>
    )
}

export default Modal;