import { React } from "react";
import "../../styles/CreateFormRow.css";
export function NotEligible() {
  return (
    <div>
      <div className="create-form-row">
        <div
          className="create-form-row-title"
          style={{ background: "#fe414d", color: "#fff" }}
        >
          <h3>
            Oops! Currently you are not authorized to take interviews please
            contact the Hiring Team.
          </h3>
        </div>
      </div>
      <div style={{ position: "absolute", top: "40%", left: "40%" }}>
        <img
          style={{ width: "300px", height: "280px" }}
          src={require("../../images/difficult.gif")}
          alt="logo"
        />
      </div>
    </div>
  );
}
