import * as React from "react";
import { useEffect, useState } from "react";
import { proxy } from "../../proxy";
import axios from "axios";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Loader from "../Loader";
import "./Reports.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { motion } from "framer-motion";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import BlackButton from "../BlackButton";
import TextField from "@mui/material/TextField";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Modal from "./PanelModal";
import PinIcon from "@mui/icons-material/Pin";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import "./Reports.css";
import { useSelector } from "react-redux";

const PanelistUtilization = (props) => {
  let date = new Date();
  date.setDate(date.getDate() - 7);
  const { user } = useAuthenticator((context) => [context.user]);
  const [isLoading, setisLoading] = useState(false);
  const [startValue, setStartValue] = React.useState(date);
  const [endValue, setEndValue] = React.useState(new Date());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [panelData, setPanelData] = useState([]);
  const [display, setDisplay] = React.useState(false);
  const [toDisplay, setToDisplay] = useState(false);
  const [totalInterviewsOrder, setTotalInterviewsOrder] = React.useState("ASC");
  const [totalWeekdaysOrder, setTotalWeekdaysOrder] = React.useState("ASC");
  const [totalWeekendsOrder, setTotalWeekendsOrder] = React.useState("ASC");
  const [totalQualifiedOrder, setTotalQualifiedOrder] = React.useState("ASC");
  const [totalDisqualifiedOrder, setTotalDisqualifiedOrder] =
    React.useState("ASC");
  const [totalReiterationOrder, setTotalReiterationOrder] =
    React.useState("ASC");
  const [showModal, setShowModal] = useState(false);
  const [interviewer, setInterviewer] = useState({});
  const profile= useSelector((state) => {
    return state.profile;
  });
  const presentProfile = user.attributes.profile;
  const ableToSwitch = user.attributes['custom:switch'];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 8));
    setPage(0);
  };

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const caps = (str) => {
    return str.split(".").map(capitalizeFirst).join(" ");
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const sortingInterviewsTaken = (col) => {
    if (totalInterviewsOrder === "ASC") {
      const sorted = [...panelData].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setPanelData(sorted);
      setTotalInterviewsOrder("DSC");
    }

    if (totalInterviewsOrder === "DSC") {
      const sorted = [...panelData].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setPanelData(sorted);
      setTotalInterviewsOrder("ASC");
    }
    // setTotalInterviewsOrder("ASC");
    setTotalWeekdaysOrder("ASC");
    setTotalWeekendsOrder("ASC");
    setTotalQualifiedOrder("ASC");
    setTotalDisqualifiedOrder("ASC");
    setTotalReiterationOrder("ASC");
  };

  const sortingWeekdaysInterviews = (col) => {
    if (totalWeekdaysOrder === "ASC") {
      const sorted = [...panelData].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setPanelData(sorted);
      setTotalWeekdaysOrder("DSC");
    }

    if (totalWeekdaysOrder === "DSC") {
      const sorted = [...panelData].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setPanelData(sorted);
      setTotalWeekdaysOrder("ASC");
    }
    setTotalInterviewsOrder("ASC");
    // setTotalWeekdaysOrder("ASC");
    setTotalWeekendsOrder("ASC");
    setTotalQualifiedOrder("ASC");
    setTotalDisqualifiedOrder("ASC");
    setTotalReiterationOrder("ASC");
  };

  const sortingWeekendsInterviews = (col) => {
    if (totalWeekendsOrder === "ASC") {
      const sorted = [...panelData].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setPanelData(sorted);
      setTotalWeekendsOrder("DSC");
    }

    if (totalWeekendsOrder === "DSC") {
      const sorted = [...panelData].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setPanelData(sorted);
      setTotalWeekendsOrder("ASC");
    }
    setTotalInterviewsOrder("ASC");
    setTotalWeekdaysOrder("ASC");
    // setTotalWeekendsOrder("ASC");
    setTotalQualifiedOrder("ASC");
    setTotalDisqualifiedOrder("ASC");
    setTotalReiterationOrder("ASC");
  };

  const sortingQualified = (col) => {
    if (totalQualifiedOrder === "ASC") {
      const sorted = [...panelData].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setPanelData(sorted);
      setTotalQualifiedOrder("DSC");
    }

    if (totalQualifiedOrder === "DSC") {
      const sorted = [...panelData].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setPanelData(sorted);
      setTotalQualifiedOrder("ASC");
    }
    setTotalInterviewsOrder("ASC");
    setTotalWeekdaysOrder("ASC");
    setTotalWeekendsOrder("ASC");
    // setTotalQualifiedOrder("ASC");
    setTotalDisqualifiedOrder("ASC");
    setTotalReiterationOrder("ASC");
  };

  const sortingDisqualified = (col) => {
    if (totalDisqualifiedOrder === "ASC") {
      const sorted = [...panelData].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setPanelData(sorted);
      setTotalDisqualifiedOrder("DSC");
    }

    if (totalDisqualifiedOrder === "DSC") {
      const sorted = [...panelData].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setPanelData(sorted);
      setTotalDisqualifiedOrder("ASC");
    }
    setTotalInterviewsOrder("ASC");
    setTotalWeekdaysOrder("ASC");
    setTotalWeekendsOrder("ASC");
    setTotalQualifiedOrder("ASC");
    // setTotalDisqualifiedOrder("ASC");
    setTotalReiterationOrder("ASC");
  };

  const sortingReiteration = (col) => {
    if (totalReiterationOrder === "ASC") {
      const sorted = [...panelData].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setPanelData(sorted);
      setTotalReiterationOrder("DSC");
    }

    if (totalReiterationOrder === "DSC") {
      const sorted = [...panelData].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setPanelData(sorted);
      setTotalReiterationOrder("ASC");
    }
    setTotalInterviewsOrder("ASC");
    setTotalWeekdaysOrder("ASC");
    setTotalWeekendsOrder("ASC");
    setTotalQualifiedOrder("ASC");
    setTotalDisqualifiedOrder("ASC");
    // setTotalReiterationOrder("ASC");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPanelistUtilizationReport();
  }, [profile]);

  const getPanelistUtilizationReport = async () => {
    if(ableToSwitch){
      try {
        setisLoading(true);
        const res = await axios.post(
          `${proxy}/api/panelutilization`,
          { startDate: startValue, endDate: endValue, profile: profile},
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        );
        console.log("Panelist Utilization\n", res.data.result);
        setisLoading(false);
        setPanelData(res.data.result);
        // setRoleMismatchData(res.data.results);
        setDisplay(false);
        setPage(0);
      } catch (error) {
        console.log(error);
      }
    }else {
      try {
        setisLoading(true);
        const res = await axios.post(
          `${proxy}/api/panelutilization`,
          { startDate: startValue, endDate: endValue, profile: presentProfile },
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        );
        console.log("Panelist Utilization\n", res.data.result);
        setisLoading(false);
        setPanelData(res.data.result);
        // setRoleMismatchData(res.data.results);
        setDisplay(false);
        setPage(0);
      } catch (error) {
        console.log(error);
      }
    }
    
  };

  const exportRoleMismatchReport = (exportData) => {
    try {
      const filteredData = exportData.map(excel =>{
        const panelDict = {};
        panelDict['Interviewer Name'] = excel['interviewerName'] || "";
        panelDict['Total Interview Taken'] = excel['totalInterviewsCount'] || 0;
        panelDict['Qualified'] = excel['Qualified'] || 0;
        panelDict['Disqualified'] = excel['Disqualified'] || 0;
        panelDict['Reiteration'] = excel['Reiteration'] || 0;
        panelDict['Weekday Count'] = excel['weekdayCount'] || 0;
        panelDict['Weekend Count'] = excel['weekendCount'] || 0;
        return panelDict;
      })
      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: "xlsx" });
      FileSaver.saveAs(data, "Panelist_Utilization_Report" + ".xlsx");
      
    } catch (error) {
      alert("Download Status : Error Downloading");
    }
  };

  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "fit-content", margin: "auto" }}>
        <div
          className="sections-header"
          style={{ height: "50px", background: "#fe414d" }}
        >
          <div className="sections-title-header">
            <p style={{ color: "white" }}>Panel Utilization Report</p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {!toDisplay && (
            <Tooltip title="Open Filters">
              <motion.div
                initial={{ y: -10 }}
                animate={{ y: 0 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowDownIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
          {toDisplay && (
            <Tooltip title="Close Filter">
              <motion.div
                initial={{ y: 0 }}
                animate={{ y: -10 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowUpIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
        </div>

        {toDisplay && (
          <motion.div
            key="box"
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ type: "spring", duration: 2.5 }}
            className="box"
          >
            <div style={{ padding: "5px", width: "100%", marginLeft: "35%" }}>
              <Grid container spacing={1} style={{}}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      label="Start Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="startDate"
                          sx={{ width: 150, marginRight: 2 }}
                        />
                      )}
                      value={startValue}
                      onChange={(newValue) => {
                        setStartValue(new Date(newValue).toISOString());
                      }}
                    />
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      minDate={startValue}
                      maxDate={new Date()}
                      label="End Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="endDate"
                          sx={{ width: 150 }}
                        />
                      )}
                      value={endValue}
                      onChange={(newValue) => {
                        setEndValue(new Date(newValue).toISOString());
                      }}
                    />
                  </LocalizationProvider>
                  <BlackButton
                    style={{ marginLeft: "6%" }}
                    onClick={getPanelistUtilizationReport}
                  >
                    Apply
                  </BlackButton>
                </Grid>
              </Grid>
            </div>
          </motion.div>
        )}
      </div>
      <div
        style={{
          width: "100%",
          marginLeft: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h2>
          <PinIcon style={{ color: "red", fontSize: "30px" }}></PinIcon> Tabular
          Data
        </h2>
        <Button
          className="btnFillup anglebg"
          style={{ height: "40px", marginRight: "100px" }}
          variant="outlined"
          onClick={()=>exportRoleMismatchReport(panelData)}
        >
          Export Data <DownloadIcon />
        </Button>
      </div>
      <div style={{ width: "90%", margin: "auto" }}>
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          interviewerData={interviewer}
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Interviewer Name</StyledTableCell>
                <StyledTableCell>
                  Total Interviews Taken&nbsp;
                  {totalInterviewsOrder === "ASC" && (
                    <Tooltip title="Sort Ascending">
                      <ArrowUpwardIcon
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          sortingInterviewsTaken("totalInterviewsCount")
                        }
                      />
                    </Tooltip>
                  )}
                  {totalInterviewsOrder === "DSC" && (
                    <Tooltip title="Sort Descending">
                      <ArrowDownwardIcon
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          sortingInterviewsTaken("totalInterviewsCount")
                        }
                      />
                    </Tooltip>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  Total Interviews on Weekdays&nbsp;
                  {totalWeekdaysOrder === "ASC" && (
                    <Tooltip title="Sort Ascending">
                      <ArrowUpwardIcon
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          sortingWeekdaysInterviews("weekdayCount")
                        }
                      />
                    </Tooltip>
                  )}
                  {totalWeekdaysOrder === "DSC" && (
                    <Tooltip title="Sort Descending">
                      <ArrowDownwardIcon
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          sortingWeekdaysInterviews("weekdayCount")
                        }
                      />
                    </Tooltip>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  Total Interviews on Weekends&nbsp;
                  {totalWeekendsOrder === "ASC" && (
                    <Tooltip title="Sort Ascending">
                      <ArrowUpwardIcon
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          sortingWeekendsInterviews("weekendCount")
                        }
                      />
                    </Tooltip>
                  )}
                  {totalWeekendsOrder === "DSC" && (
                    <Tooltip title="Sort Descending">
                      <ArrowDownwardIcon
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          sortingWeekendsInterviews("weekendCount")
                        }
                      />
                    </Tooltip>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  Qualified&nbsp;
                  {totalQualifiedOrder === "ASC" && (
                    <Tooltip title="Sort Ascending">
                      <ArrowUpwardIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => sortingQualified("Qualified")}
                      />
                    </Tooltip>
                  )}
                  {totalQualifiedOrder === "DSC" && (
                    <Tooltip title="Sort Descending">
                      <ArrowDownwardIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => sortingQualified("Qualified")}
                      />
                    </Tooltip>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  Reiterations&nbsp;
                  {totalReiterationOrder === "ASC" && (
                    <Tooltip title="Sort Ascending">
                      <ArrowUpwardIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => sortingReiteration("Reiteration")}
                      />
                    </Tooltip>
                  )}
                  {totalReiterationOrder === "DSC" && (
                    <Tooltip title="Sort Descending">
                      <ArrowDownwardIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => sortingReiteration("Reiteration")}
                      />
                    </Tooltip>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  Disqualified&nbsp;
                  {totalDisqualifiedOrder === "ASC" && (
                    <Tooltip title="Sort Ascending">
                      <ArrowUpwardIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => sortingDisqualified("Disqualified")}
                      />
                    </Tooltip>
                  )}
                  {totalDisqualifiedOrder === "DSC" && (
                    <Tooltip title="Sort Descending">
                      <ArrowDownwardIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => sortingDisqualified("Disqualified")}
                      />
                    </Tooltip>
                  )}
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {!isLoading && (
              <TableBody>
                {panelData &&
                  panelData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((document) => (
                      <StyledTableRow
                        hover
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Tooltip title="Click to view Profile">
                            <span
                              onClick={() => {
                                axios
                                  .post(
                                    `${proxy}/api/interviewerprofile`,
                                    {
                                      interviewerName: document.interviewerName,
                                    },
                                    {
                                      withCredentials: true,
                                      headers: {
                                        Accept: "application/json",
                                        Authorization:
                                          user.signInUserSession.idToken
                                            .jwtToken,
                                        "Content-Type": "application/json",
                                        "Access-Control-Allow-Credentials": true,
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    console.log(res.data.result);
                                    setInterviewer(res.data.result);
                                    setShowModal(true);
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {caps(document.interviewerName)}
                            </span>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell>
                          {document.totalInterviewsCount}
                        </StyledTableCell>
                        <StyledTableCell>
                          {document.weekdayCount}
                        </StyledTableCell>
                        <StyledTableCell>
                          {document.weekendCount}
                        </StyledTableCell>
                        <StyledTableCell>
                          {document.Qualified ? document.Qualified : 0}
                        </StyledTableCell>
                        <StyledTableCell>
                          {document.Reiteration ? document.Reiteration : 0}
                        </StyledTableCell>
                        <StyledTableCell>
                          {document.Disqualified ? document.Disqualified : 0}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
            )}
            {isLoading && <Loader />}
          </Table>
          {panelData.length === 0 && (
            <div
              style={{ textAlign: "center", fontSize: "24px", width: "100%" }}
            >
              {display ? "See the Form Below" : "No Results Found"}
            </div>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={panelData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </React.Fragment>
  );
};

export default PanelistUtilization;
