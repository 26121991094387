import axios from 'axios';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { proxy } from '../../proxy';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import Loader from '../Loader';
import "./interviewerform.css";
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Autosave, useAutosave } from 'react-autosave';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function InterviewFormSectionPage(props) {

  const [blockData,setBlockData] = useState([]);
  const {user} = useAuthenticator((context) => [context.user]);
  const [marks, setMarks] = useState(-1);
  const [remarks, setRemarks] = useState("");
  const [recommendedProbeArea, setRecommendedProbeAreas] = useState([""]);
  const [isLoading, setisLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [autosave, setAutosave] = React.useState(false);
  let probeNum = 1
  let expectNum = 1

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleAutosaveClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAutosave(false);
  };


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeMarks = (event) => {
    setMarks(event.target.value);
  };

  const handleChangeRemarks = (event) => {
    setRemarks(event.target.value);
  };

  const handleRecommendedProbeAreas = (event, index) => {
    const inputsUpdated = recommendedProbeArea.map((input, i) => {
      if (i === index) {
        return event.target.value;
      } else {
        return input;
      }
    });
    // console.log("Logging Data==>", inputsUpdated);
    setRecommendedProbeAreas(inputsUpdated);
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    width:'100px',
    '&:hover': {
      backgroundColor: blue[700],
    },
  }));

  useEffect(()=>{
    window.scrollTo(0,0)
    axios.get(`${proxy}/api/formblock/${props.formId}/${props.blockId}`, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        'Authorization':user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    }).then((res) => {
      console.log(res.data.result);
      setisLoading(false);
      setBlockData(res.data.result[0]);
      setMarks(res.data.result[0].marks)
      setRemarks(res.data.result[0].remarks)
      const output = Object.entries(
        res.data.result[0].recommendedProbeAreas
      ).map(([key, value]) => {
        return value;
      });
      setRecommendedProbeAreas(output);
    }).catch((error)=>{
      setisLoading(false);
      console.log(error)
    });

    const interval = setInterval(() => {
      const formSubmitButton = document.querySelector(".saveInfoForm");
      formSubmitButton.click();
    }, 15 * 60 * 1000);
    return ()=> clearInterval(interval);
    
  },[])

  const sendSectionFormData = async () => {
    try {
      let requestBody = {
        "formId": props.formId,
        "blockId": props.blockId,
        "marks": marks,
        "remarks": remarks,
        
      };
  
      if (blockData && blockData.recommendedProbeAreas) {
        requestBody["recommendedBlockData"] = blockData.recommendedProbeAreas;
        requestBody["recommendedProbeAreasFeedback"] = recommendedProbeArea;
      }
  
      const response = await axios.put(`${proxy}/api/candidatedetails`, requestBody, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Access-Control-Allow-Credentials": true,
        },
      });
  
      console.log(response.data);
      props.didSave();
      setOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const autoSaveSectionData = async() => {

    try {
      let requestBody = {
        "formId": props.formId,
        "blockId": props.blockId,
        "marks": marks,
        "remarks": remarks,
      };
  
      if (blockData && blockData.recommendedProbeAreas) {
        requestBody["recommendedBlockData"] = blockData.recommendedProbeAreas;
        requestBody["recommendedProbeAreasFeedback"] = recommendedProbeArea;
      }
  
      const response = await axios.put(`${proxy}/api/candidatedetails`, requestBody, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Access-Control-Allow-Credentials": true,
        },
      });
      props.didSave();
      setAutosave(true);
    } catch (err) {
      console.log(err);
    }

  }

  return (
    <div className="form-section-div">
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Section Saved Successfully at {new Date().toLocaleTimeString()} !!
        </Alert>
      </Snackbar>

      <Snackbar
        open={autosave}
        autoHideDuration={5000}
        onClose={handleAutosaveClose}
      >
        <Alert
          onClose={handleAutosaveClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Autosaved Successfully at {new Date().toLocaleTimeString()} !!
        </Alert>
      </Snackbar>

      {isLoading && <Loader />}
      {!isLoading && (
        <div className="section-wrapper">
          <div className="sections-box">
            <div className="section-box">
              <p className="section-heading">Section Name</p>
              <p className="section-content">{blockData.sectionName}</p>
            </div>
            <div className="section-box">
              <p className="section-heading">Sub-Section Name</p>
              <p className="section-content">{blockData.subSectionName}</p>
            </div>
            <div
              className="section-box"
              style={{ alignSelf: "flex-end", marginBottom: "8px" }}
            >
              <p
                className="section-heading"
                style={{ paddingBottom: "5px", marginLeft: "7px" }}
              >
                Ratings
              </p>
              <FormControl required sx={{ m: 1, minWidth: 120 }}>
                <Select
                  autoFocus
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  name="marks"
                  value={marks}
                  onChange={handleChangeMarks}
                  style={{ height: "40px" }}
                  // {...register('marks')}
                >
                  <MenuItem>Select</MenuItem>
                  <MenuItem key={0} value={0}>
                    No Experience
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    Beginner
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    Intermediate
                  </MenuItem>
                  <MenuItem key={3} value={3}>
                    Proficient
                  </MenuItem>
                  <MenuItem key={4} value={4}>
                    Expert
                  </MenuItem>
                </Select>
                <Autosave data={marks} onSave={autoSaveSectionData} />
                {/* <FormHelperText>{errors.marks?.message}</FormHelperText> */}
              </FormControl>
            </div>
            <div
              className="section-box"
              style={{ alignSelf: "flex-end", marginBottom: "18px" }}
            >
              <Tooltip title="Save Section">
                <ColorButton
                  className="saveInfoForm"
                  variant="contained"
                  endIcon={<SendIcon />}
                  type="submit"
                  onClick={sendSectionFormData}
                >
                  Save
                </ColorButton>
              </Tooltip>
            </div>
          </div>
          {blockData.recommendedProbeAreas &&
          <div className="sections-box">
            
            <div className="section-box">
              <Typography
                style={{
                  color: "grey",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Recommended Probe Areas
              </Typography>
              {Object.entries(blockData.recommendedProbeAreas).map(
                ([probearea, value], index) => {
                  return (
                    <React.Fragment>
                      <div className="sections-box">
                        <div className="section-box">
                          <p
                            className="section-content"
                            style={{
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            {probearea}
                          </p>
                        </div>
                        <div className="section-box">
                          <TextField
                            style={{ width: "400%", paddingBottom: "10px" }}
                            id="outlined-multiline-static"
                            multiline
                            rows={2}
                            autoFocus
                            value={recommendedProbeArea[index]}
                            onChange={(e) =>
                              handleRecommendedProbeAreas(e, index)
                            }
                            placeholder="Enter Feedback"
                            name="feedback"
                          />
                          <Autosave
                            data={recommendedProbeArea[index]}
                            onSave={autoSaveSectionData}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }
              )}
            </div>
            <div className="section-box">
              <div className="remarks" style={{ marginLeft: "450px" }}>
                <Typography
                  style={{
                    color: "grey",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  Additional Comments
                </Typography>
                <TextField
                  style={{ width: "145%" }}
                  id="outlined-multiline-static"
                  autoFocus
                  multiline
                  rows={9}
                  value={remarks}
                  onChange={handleChangeRemarks}
                  placeholder="Enter Remarks"
                  name="remarks"
                />
                <Autosave data={remarks} onSave={autoSaveSectionData} />
              </div>
            </div>
          </div>}

          { !blockData.recommendedProbeAreas && 
          <div className='remarks'>
          <Typography style={{color: 'grey', paddingTop:'5px', paddingBottom:'5px'}}>Remarks</Typography>
          <TextField style={{width:'100%'}}
            id="outlined-multiline-static"
            autoFocus
            multiline
            rows={3}
            value={remarks}
            onChange={handleChangeRemarks}
            placeholder="Enter Remarks"
            name="remarks"
          />
          <Autosave data={remarks} onSave={autoSaveSectionData} />
        </div> }
          <div className="probeareas-expectations">
            <div className="probeareas-div">
              <Typography
                style={{
                  color: "grey",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
              >
                Probe Areas
              </Typography>
              <Tooltip
                title={
                  expanded == false ? "Click to expand" : "Click to collapse"
                }
              >
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Probe Areas
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {blockData != null &&
                      blockData.probeAreas.map((probe, index) => {
                        return (
                          <Typography
                            key={index}
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            {probeNum++ + ". " + probe}
                          </Typography>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              </Tooltip>
            </div>
            <div className="probeareas-div">
              <Typography
                style={{
                  color: "grey",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
              >
                Expectations
              </Typography>
              <Tooltip
                title={
                  expanded == false ? "Click to expand" : "Click to collapse"
                }
              >
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Expectations
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {blockData &&
                      blockData.expectations.map((expect, index) => {
                        return (
                          <Typography
                            key={index}
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            {expectNum++ + ". " + expect}
                          </Typography>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </div>

  )
}
