import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';




const backdrop = {
    visible : { opacity: 1 },
    hidden : { opacity: 0 }
}

const modal = {
    hidden: {
        y: "-100vh",
        opacity: 0
    },
    visible: {
        y: "50px",
        opacity: 1,
        transition: { delay: 0.5 }
    }
}

const RemarkModal = ({showRemarks,setRemarksDisplay,remarks}) => {
    const handleModal = () => {
        setRemarksDisplay(false);
    }

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
      const caps = str => {
        return str.split('.').map(capitalizeFirst).join(' ');
      };
    var isRemark= false;
    if(remarks.length !== 0)
    {
        isRemark=true;
    }
    
    return (
        <AnimatePresence exitBeforeEnter>
            {showRemarks && (
                <motion.div className='backdrop'
                    variants={backdrop}
                    initial="hidden"
                    animate="visible" 
                    exit="hidden" 
                >
                    <motion.div className='modal' 
                    variants={modal}
                    >
                        <div>
                            <Tooltip title="Close">
                                <CloseIcon style={{color:"#FE414D",position:'absolute',right:'15px', top:'15px', cursor:'pointer'}} onClick={handleModal}/>
                            </Tooltip>
                            <h3 style={{textAlign:'center'}}>Remarks</h3>
                            <TableContainer component={Paper} style={{width:"100%", padding:'20px', marginTop:'10px',marginBottom:'10px'}}>
                                <Table sx={{ miWidth: 250 }} size="small" aria-label="a dense table">
                                <TableBody>
                                {isRemark && 
                                <TableRow>
                                        <TableCell style={{fontWeight: "bold"}}>Action-Remark</TableCell>
                                        <TableCell style={{textAlign:"center" ,fontWeight: "bold"}}>Panel Status</TableCell>
                                        <TableCell style={{textAlign:"center" ,fontWeight: "bold"}}>Time Stamp</TableCell>
                                        <TableCell style={{textAlign:"right" ,fontWeight: "bold"}}>Edited By</TableCell>
                                        </TableRow>
                                }
                                    {isRemark && remarks.map(({remark,postedDate,editedBy, activeStatus},index) => {
                                     return(   <TableRow>
                                        <TableCell>{remark}</TableCell>
                                        <TableCell style={{textAlign:"center"}}>{activeStatus === true ? 'Active': activeStatus === false ? 'InActive' : 'Not Determined'}</TableCell>
                                        <TableCell style={{textAlign:"center"}}>{postedDate}</TableCell>
                                        <TableCell style={{textAlign:"right"}}>{editedBy}</TableCell>
                                        </TableRow>)
                                    }
                                    )
                                    }
                                    {!isRemark && <TableRow>
                                        <TableCell style={{fontWeight:'bold'}}>Sorry No Remarks</TableCell>
                                    </TableRow>
                                    }
                                </TableBody>
                                </Table>
                            </TableContainer>
                            </div>
                    </motion.div>
                </motion.div>

            )}

        </AnimatePresence>
    )
}

export default RemarkModal;