// import { useAuthenticator } from '@aws-amplify/ui-react';
import {createSlice,createAsyncThunk } from "@reduxjs/toolkit"

import axios from "axios";
import { proxy } from "../proxy";

let initialState={
    "isLoading"         : false,
    "sectionId"        :   "",
    "section"           :"",
    "subSection"        :"",
    "subSectionId"     :   "",
    "probeAreas"        :   [],
    "recommendedProbeAreas":[],
	"variants"	:	[],
    displayingindex:"0",
   
}



export const sendForm = createAsyncThunk('/api/buildingblocks', async (input) => {
    
    // const { user} = useAuthenticator((context) => [context.user]);
    try {
        const response = await axios.put(`${proxy}/api/buildingblocks`, input.data, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization':input.jwtToken,
              "Access-Control-Allow-Credentials": true,
            },
          })

        return response.data;
    } catch (err) {        
        return input;
    }
})
export const getFormData = createAsyncThunk('/api/buildingblocks/id', async (data) => {
    console.log(data)
    // const { user} = useAuthenticator((context) => [context.user]);
    
    console.log("blockslice")
    try {
        const response = await axios.get(`${proxy}/api/buildingblocks/${data.id}`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization':data.jwtToken,
              "Access-Control-Allow-Credentials": true,
            },
          } )
        return response.data
    } catch (err) {        
        return err;
    }
})


const blockSlice=createSlice({
    name:'block',
    initialState,
    reducers:{
        addRole:(state,action)=>{
            const element={
                "roleId" 		: action.payload.roleId,
                "role"          :action.payload.role,
                "expectations"	:[] 
            }
            for (let index = 0; index < state.variants.length; index++) {
                if(state.variants[index].role===action.payload.role){
                    alert("role found")
                    return
                }
                
            }
        
            state.variants.push(element);
        },
        removeRole:(state,action)=>{
            // console.log(action.payload.index)
            const index=action.payload.index;
            if (index > -1) {
                state.variants.splice(index, 1);
              }
              
        },
        addProbeArea:(state,action)=>{
            state.probeAreas.push(action.payload.probearea);
        },
        removeProbeArea:(state,action)=>{
            const index=action.payload.index;
            if (index > -1) {
                state.probeAreas.splice(index, 1);
              }
        },
        addRecommendedProbeArea:(state,action)=>{
            console.log("State : ", state.recommendedProbeAreas)
            console.log("action: ", action.payload.recommendedProbeArea)
            state.recommendedProbeAreas.push(action.payload.recommendedProbeArea);
            // console.log(state.recommendedProbeAreas)
        },
        removeRecommendedProbeArea:(state,action)=>{
            const index=action.payload.index;
            if (index > -1) {
                state.recommendedProbeAreas.splice(index, 1);
              }
            //   console.log("Removed",state.recommendedProbeAreas)
        },
        addKeyPoint:(state,action)=>{
            // console.log("reducer:",action)
            const roleIndex=action.payload.index
            const keyPoint=action.payload.text
            state.variants[roleIndex].expectations.push(keyPoint)
        },
        removeKeyPoint:(state,action)=>{
            const roleIndex=action.payload.roleindex
            const index=action.payload.index
            if (index > -1 && roleIndex>-1) {
                state.variants[roleIndex].expectations.splice(index, 1);
              }
        },
        changeSection:(state,action)=>{
            state.sectionId=action.payload.sectionId
            // state.section=action.payload.section;
        },
        changeSubSection:(state,action)=>{
            console.log("reducer",action.payload)
            state.subSectionId=action.payload.subSectionId
            // state.subSection=action.payload.subSection;
        },
        changedisplayingindex:(state,action)=>{
            console.log(action.index)
            state.displayingindex=action.payload.index;
        },
        setToInitialState:(state)=>{

            state.isLoading=false;
            state.section="";
            state.sectionId="";
            state.subSectionId="";
            state.probeAreas=[];
            state.recommendedProbeAreas=[];
            state.variants=[];
        },

    },
    extraReducers(builder) {
        builder.addCase(sendForm.fulfilled, (state, action) => {
            state.isLoading=false;

            alert("succesfully submitted the block");
            return ;
        })
        builder.addCase(sendForm.pending, (state, action) => {
            state.isLoading=true;
            return ;
        })
        builder.addCase(sendForm.rejected, (state, action) => {
            alert("Error");
            state.isLoading=false;
            return ;
        })
        builder.addCase(getFormData.fulfilled, (state, action) => {
            // console.log("blockslice",action.payload)
            state.sectionId=action.payload.sectionId;
            state.subSectionId=action.payload.subSectionId;
            state.section=action.payload.section;
            state.subSection=action.payload.subSection;
            state.probeAreas=action.payload.probeAreas;
            state.recommendedProbeAreas=[];
            state.variants=action.payload.variants;
            state.isLoading=false;

            return ;
        })
        builder.addCase(getFormData.pending, (state, action) => {
            state.isLoading=true;
            return ;
        })
        builder.addCase(getFormData.rejected, (state, action) => {
            alert("Error");
            state.isLoading=false;
            return ;
        })
    }
   
});

export const {addRole,removeRole,addKeyPoint,removeKeyPoint,changeSection,changeSubSection,changedisplayingindex,addProbeArea,removeProbeArea,addRecommendedProbeArea,removeRecommendedProbeArea,setToInitialState}=blockSlice.actions
export default blockSlice.reducer


// extraReducers(builder) {
//     builder.addCase(sendForm.fulfilled, (state, action) => {
//         state.isLoading=false;

//         alert("succesfully submitted the block");
//         return ;
//     })
//     builder.addCase(sendForm.pending, (state, action) => {
//         state.isLoading=true;
//         return ;
//     })
//     builder.addCase(sendForm.rejected, (state, action) => {
//         alert("Error");
//         state.isLoading=false;
//         return ;
//     })
//     builder.addCase(getFormData.fulfilled, (state, action) => {
//         console.log("blockslice",action.payload)
//         state.sectionId=action.payload.sectionId;
//         state.subSectionId=action.payload.subSectionId;
//         state.probeAreas=action.payload.probeAreas;
//         state.variants=action.payload.variants;
//         state.isLoading=false;

//         return ;
//     })
//     builder.addCase(getFormData.pending, (state, action) => {
//         state.isLoading=true;
//         return ;
//     })
//     builder.addCase(getFormData.rejected, (state, action) => {
//         alert("Error");
//         state.isLoading=false;
//         return ;
//     })
// }