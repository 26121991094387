import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Box
} from "@mui/material";
import { proxy } from "../../proxy";
import axios from "axios";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { motion } from "framer-motion";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import BlackButton from "../BlackButton";
import { tidy, distinct, filter } from "@tidyjs/tidy";
import "./Reports.css";
import Chip from "@mui/material/Chip";
import DownloadIcon from "@mui/icons-material/Download";
import CheckboxGroup from "./CheckboxGroup";
import MultiSelectDropdown from "./MultiSelectDropdown";
import MultiSelectRegionDropdown from "./MultiSelectRegionDropdown";
import TuneIcon from "@mui/icons-material/Tune";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Loader from "../Loader";
import { useSelector } from "react-redux";

const PanelReport = (props) => {
  let date = new Date();
  date.setDate(date.getDate() - 7);

  const { user } = useAuthenticator((context) => [context.user]);
  const [panelData, setPanelData] = useState([]);
  //   const [interviewerName, setInterviewerName] = React.useState("");
  const [startValue, setStartValue] = React.useState(date);
  const [endValue, setEndValue] = React.useState(new Date());
  const [toDisplay, setToDisplay] = useState(false);
  const [isLoading, setisLoading] = React.useState(false);
  const [displayingData, setDisplayingData] = React.useState([]);
  const [showHeader, setShowHeader] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [interviewers, setInterviewers] = React.useState([]);
  const [regions, setRegions] = React.useState([]);
  const [roleData, setRoleData] = useState([]);
  const [totalInterviewsOrder, setTotalInterviewsOrder] = React.useState("ASC");
  const [totalWeekdaysOrder, setTotalWeekdaysOrder] = React.useState("ASC");
  const [totalWeekendsOrder, setTotalWeekendsOrder] = React.useState("ASC");
  const [totalQualifiedOrder, setTotalQualifiedOrder] = React.useState("ASC");
  const [totalDisqualifiedOrder, setTotalDisqualifiedOrder] =
    React.useState("ASC");
  const [totalReiterationOrder, setTotalReiterationOrder] =
    React.useState("ASC");

  const [expertiseFilters, setExpertiseFilters] = useState([]);
  const [roleFilters, setRoleFilters] = useState([]);
  const [regionFilters, setRegionFilters] = useState([]);
  const [panelType, setPanelType] = useState("All");
  const profile= useSelector((state) => {
    return state.profile;
  });

  const presentProfile = user.attributes.profile;
  const ableToSwitch = user.attributes['custom:switch'];

  const handleExpertiseFilters = (filters) => {
    setExpertiseFilters(filters);
  };

  const handleRoleFilters = (filters) => {
    setRoleFilters(filters);
  };

  const handleRegionFilters = (filters) => {
    setRegionFilters(filters);
  };
  
  const handlePanelType = (event) => {
    setPanelType(event.target.value);
  }

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const caps = (str) => {
    return str.split(".").map(capitalizeFirst).join(" ");
  };

  const sortingInterviewsTaken = (col) => {
    if (totalInterviewsOrder === "ASC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setTotalInterviewsOrder("DSC");
    }

    if (totalInterviewsOrder === "DSC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setTotalInterviewsOrder("ASC");
    }
    // setTotalInterviewsOrder("ASC");
    setTotalWeekdaysOrder("ASC");
    setTotalWeekendsOrder("ASC");
    setTotalQualifiedOrder("ASC");
    setTotalDisqualifiedOrder("ASC");
    setTotalReiterationOrder("ASC");
  };

  const sortingWeekdaysInterviews = (col) => {
    if (totalWeekdaysOrder === "ASC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setTotalWeekdaysOrder("DSC");
    }

    if (totalWeekdaysOrder === "DSC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setTotalWeekdaysOrder("ASC");
    }
    setTotalInterviewsOrder("ASC");
    // setTotalWeekdaysOrder("ASC");
    setTotalWeekendsOrder("ASC");
    setTotalQualifiedOrder("ASC");
    setTotalDisqualifiedOrder("ASC");
    setTotalReiterationOrder("ASC");
  };

  const sortingWeekendsInterviews = (col) => {
    if (totalWeekendsOrder === "ASC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setTotalWeekendsOrder("DSC");
    }

    if (totalWeekendsOrder === "DSC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setTotalWeekendsOrder("ASC");
    }
    setTotalInterviewsOrder("ASC");
    setTotalWeekdaysOrder("ASC");
    // setTotalWeekendsOrder("ASC");
    setTotalQualifiedOrder("ASC");
    setTotalDisqualifiedOrder("ASC");
    setTotalReiterationOrder("ASC");
  };

  const sortingQualified = (col) => {
    if (totalQualifiedOrder === "ASC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setTotalQualifiedOrder("DSC");
    }

    if (totalQualifiedOrder === "DSC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setTotalQualifiedOrder("ASC");
    }
    setTotalInterviewsOrder("ASC");
    setTotalWeekdaysOrder("ASC");
    setTotalWeekendsOrder("ASC");
    // setTotalQualifiedOrder("ASC");
    setTotalDisqualifiedOrder("ASC");
    setTotalReiterationOrder("ASC");
  };

  const sortingDisqualified = (col) => {
    if (totalDisqualifiedOrder === "ASC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setTotalDisqualifiedOrder("DSC");
    }

    if (totalDisqualifiedOrder === "DSC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setTotalDisqualifiedOrder("ASC");
    }
    setTotalInterviewsOrder("ASC");
    setTotalWeekdaysOrder("ASC");
    setTotalWeekendsOrder("ASC");
    setTotalQualifiedOrder("ASC");
    // setTotalDisqualifiedOrder("ASC");
    setTotalReiterationOrder("ASC");
  };

  const sortingReiteration = (col) => {
    if (totalReiterationOrder === "ASC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setTotalReiterationOrder("DSC");
    }

    if (totalReiterationOrder === "DSC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setTotalReiterationOrder("ASC");
    }
    setTotalInterviewsOrder("ASC");
    setTotalWeekdaysOrder("ASC");
    setTotalWeekendsOrder("ASC");
    setTotalQualifiedOrder("ASC");
    setTotalDisqualifiedOrder("ASC");
    // setTotalReiterationOrder("ASC");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 8));
    setPage(0);
  };

  function findIsPresent(array1, array2) {
    for (let i = 0; i < array1.length; i++) {
      if (array2.includes(array1[i])) {
        return true; // Return true if any option from array1 is present in array2
      }
    }
    return false; // Return false if none of the options from array1 are present in array2
  }

  const getPanelistUtilizationReport = async () => {
    if(ableToSwitch){
      try {
        setisLoading(true);
        const res = await axios.post(
          `${proxy}/api/panelreport`,
          { startDate: startValue, endDate: endValue, profile: profile, },
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        );
        console.log("Panelist Utilization\n", res.data.result);
        setisLoading(false);
        setPanelData(res.data.result);
        setDisplayingData(res.data.result);
        initializeInterviewerArray(res.data.result);
        setPage(0);
      } catch (error) {
        console.log(error);
      }
    }else{
      try {
        setisLoading(true);
        const res = await axios.post(
          `${proxy}/api/panelreport`,
          { startDate: startValue, endDate: endValue, profile: presentProfile, },
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        );
        console.log("Panelist Utilization\n", res.data.result);
        setisLoading(false);
        setPanelData(res.data.result);
        setDisplayingData(res.data.result);
        initializeInterviewerArray(res.data.result);
        setPage(0);
      } catch (error) {
        console.log(error);
      }
    }
    
  };

  useEffect(() => {
    getPanelistUtilizationReport();

    axios
      .get(`${proxy}/api/role`, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: user.signInUserSession.idToken.jwtToken,

          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        setRoleData(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [profile]);

  useEffect(() => {
    let filteredData = panelData;
    if (expertiseFilters.length > 0) {
      filteredData = filteredData.filter((d) =>
        findIsPresent(
          expertiseFilters,
          d.interviewerDetails.interviewerExpertise
        )
      );
    }
    if (roleFilters.length > 0) {
      filteredData = filteredData.filter((d) =>
        findIsPresent(roleFilters, d.interviewerDetails.roleTypeAllowed)
      );
    }
    if (regionFilters.length > 0) {
      // console.log("Region Text", regionFilters);
      filteredData = filteredData.filter((d) =>
        regionFilters.includes(d.region)
      );
    }

    if (panelType === "Seasoned") {
      const sixMonthsFromNow = new Date();
      sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() - 6);

    filteredData = filteredData.filter((panel) => {
      const empaneledDate = new Date(panel.interviewerDetails.empaneledDate);
      return empaneledDate < sixMonthsFromNow;
      });
    } else if (panelType === "New") {
      const currentDate = new Date();
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
  
      filteredData = filteredData.filter((panel) => {
        const empaneledDate = new Date(panel.interviewerDetails.empaneledDate);
        return empaneledDate <= currentDate && empaneledDate >= sixMonthsAgo;
      });
    } else if (panelType === "All") {

    }
    setDisplayingData(filteredData);
  }, [expertiseFilters, roleFilters, regionFilters, panelType]);

  function initializeInterviewerArray(data) {
    let interviewers = [];
    let regions = [];
    if (data !== null && data !== undefined) {
      data.map(({ interviewerName, region }) => {
        interviewers.push(interviewerName);
        regions.push(region);
        return 1;
      });
      setInterviewers(tidy(interviewers, distinct([])));
      setRegions(tidy(regions, distinct([])));
    }
  }

  const exportRoleMismatchReport = (displayData) => {
    // console.log("displayData", displayData);
    try {
        const filteredData = displayData.map(item => {
            const {
                interviewerDetails: {
                    fullName,
                    username: interviewerName,
                    interviewerExpertise,
                    positionTitle,
                    region
                },
                currentStatus,
                editedBy,
                editedOn,
                totalInterviewsCount: totalInterviewsTaken,
                Qualified,
                Disqualified,
                Reiteration,
                weekdayCount,
                weekendCount
            } = item;

            const {
              onboardingDate,
              empaneledDate,
              disempaneledDate
          } = item.interviewerDetails;

            return {
              fullName: fullName || "", 
              interviewerName: interviewerName || "",
              interviewerExpertise: interviewerExpertise ? interviewerExpertise.join(', ') : "", 
              positionTitle: positionTitle || "",
              region: region || "",
              currentStatus: currentStatus || "",
              onboardingDate: onboardingDate || "", 
              empaneledDate: empaneledDate || "", 
              disempaneledDate: disempaneledDate || "", 
              editedBy: editedBy || "",
              editedOn: editedOn || "",
              totalInterviewsTaken: totalInterviewsTaken || 0, 
              Qualified: Qualified || 0,
              Disqualified: Disqualified || 0,
              Reiteration: Reiteration || 0,
              weekdayCount: weekdayCount || 0,
              weekendCount: weekendCount || 0
          };
        });

        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const currentDateTimeString = getCurrentDateTimeString();
        const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        FileSaver.saveAs(data, `PanelistUtlizationReport-${currentDateTimeString}.xlsx`);
    } catch (error) {
        alert("Download Status: Error Downloading");
    }
};
const getCurrentDateTimeString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
};


  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "fit-content", margin: "auto" }}>
        <div
          className="sections-header"
          style={{ height: "50px", background: "#fe414d" }}
        >
          <div className="sections-title-header">
            <p style={{ color: "white" }}>Full Panel Report</p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {!toDisplay && (
            <Tooltip title="Open Filters">
              <motion.div
                initial={{ y: -10 }}
                animate={{ y: 0 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowDownIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
          {toDisplay && (
            <Tooltip title="Close Filter">
              <motion.div
                initial={{ y: 0 }}
                animate={{ y: -10 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowUpIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
        </div>

        {toDisplay && (
          <motion.div
            key="box"
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ type: "spring", duration: 2.5 }}
            className="box"
          >
            <div style={{ padding: "5px", width: "100%", marginLeft: "35%" }}>
              <Grid container spacing={1} style={{}}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      label="Start Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="startDate"
                          sx={{ width: 150, marginRight: 2 }}
                        />
                      )}
                      value={startValue}
                      onChange={(newValue) => {
                        setStartValue(new Date(newValue).toISOString());
                      }}
                    />
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      minDate={startValue}
                      maxDate={new Date()}
                      label="End Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="endDate"
                          sx={{ width: 150 }}
                        />
                      )}
                      value={endValue}
                      onChange={(newValue) => {
                        setEndValue(new Date(newValue).toISOString());
                      }}
                    />
                  </LocalizationProvider>
                  <BlackButton
                    style={{ marginLeft: "6%" }}
                    onClick={getPanelistUtilizationReport}
                  >
                    Apply
                  </BlackButton>
                </Grid>
              </Grid>
            </div>
          </motion.div>
        )}
      </div>
      {!isLoading && (
        <React.Fragment>
          <TableContainer
            component={Paper}
            sx={{ minWidth: 650, maxWidth: "100%", margin: "auto" }}
          >
            <Table
              sx={{ minWidth: 650, maxWidth: "100%" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead
                style={{
                  width: "100%",
                }}
              >
                <TableRow>
                  <TableCell align="center">
                    <TuneIcon
                      style={{
                        background: "#fe414d",
                        borderRadius: "50%",
                        color: "black",
                        fontSize: "35px",
                        padding: "4px",
                      }}
                    />
                  </TableCell>
                  <CheckboxGroup handleFilters={handleExpertiseFilters} />
                  <TableCell align="center">
                    <Box sx={{ minWidth: 100 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Panel Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={panelType}
                          label="Panel Type"
                          onChange={handlePanelType}
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          <MenuItem value={"Seasoned"}>Seasoned</MenuItem>
                          <MenuItem value={"New"}>New</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <MultiSelectDropdown
                      roleData={roleData}
                      handleRolesFilters={handleRoleFilters}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <MultiSelectRegionDropdown
                      regionData={regions}
                      handleRegionFilters={handleRegionFilters}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      starticon={<DownloadIcon />}
                      variant="outlined"
                      size="medium"
                      onClick={()=>exportRoleMismatchReport(displayingData)}
                    >
                      Export Data
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <TableContainer
            component={Paper}
            sx={{ minWidth: 650, maxWidth: "100%", margin: "auto" }}
          >
            <Table>
              <TableBody>
                {showHeader && (
                  <TableRow
                    key={-1}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        fontSize: "large",
                      },
                    }}
                  >
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      Interviewer Name
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      Position
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      Region
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      Cloud Expertise
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      Eligible Upto
                    </TableCell>
                    <TableCell>
                      Total Interviews Taken&nbsp;
                      {totalInterviewsOrder === "ASC" && (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              sortingInterviewsTaken("totalInterviewsCount")
                            }
                          />
                        </Tooltip>
                      )}
                      {totalInterviewsOrder === "DSC" && (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              sortingInterviewsTaken("totalInterviewsCount")
                            }
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      Total Interviews on Weekdays&nbsp;
                      {totalWeekdaysOrder === "ASC" && (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              sortingWeekdaysInterviews("weekdayCount")
                            }
                          />
                        </Tooltip>
                      )}
                      {totalWeekdaysOrder === "DSC" && (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              sortingWeekdaysInterviews("weekdayCount")
                            }
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      Total Interviews on Weekends&nbsp;
                      {totalWeekendsOrder === "ASC" && (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              sortingWeekendsInterviews("weekendCount")
                            }
                          />
                        </Tooltip>
                      )}
                      {totalWeekendsOrder === "DSC" && (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              sortingWeekendsInterviews("weekendCount")
                            }
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      Qualified&nbsp;
                      {totalQualifiedOrder === "ASC" && (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => sortingQualified("Qualified")}
                          />
                        </Tooltip>
                      )}
                      {totalQualifiedOrder === "DSC" && (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => sortingQualified("Qualified")}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      Reiterations&nbsp;
                      {totalReiterationOrder === "ASC" && (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => sortingReiteration("Reiteration")}
                          />
                        </Tooltip>
                      )}
                      {totalReiterationOrder === "DSC" && (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => sortingReiteration("Reiteration")}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      Disqualified&nbsp;
                      {totalDisqualifiedOrder === "ASC" && (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => sortingDisqualified("Disqualified")}
                          />
                        </Tooltip>
                      )}
                      {totalDisqualifiedOrder === "DSC" && (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => sortingDisqualified("Disqualified")}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                )}

                {displayingData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">
                        {data.interviewerDetails &&
                          data.interviewerDetails.fullName}
                        {!data.interviewerDetails && caps(data.interviewerName)}
                      </TableCell>
                      <TableCell>
                        {data.interviewerDetails &&
                          data.interviewerDetails.positionTitle}
                        {!data.interviewerDetails && (
                          <span style={{ color: "red" }}>No Data</span>
                        )}
                      </TableCell>
                      <TableCell align="left" style={{ cursor: "pointer" }}>
                        {data.region ? data.region : "N/A"}
                      </TableCell>
                      <TableCell>
                        {data.interviewerDetails &&
                          data.interviewerDetails.interviewerExpertise.map(
                            (expertise) => {
                              return (
                                <React.Fragment>
                                  <Chip
                                    label={expertise}
                                    style={{
                                      backgroundColor:
                                        expertise === "AWS"
                                          ? "#FF9900"
                                          : expertise === "Azure"
                                          ? "#0080FF"
                                          : "#0F9D58",
                                      color: "white",
                                      marginLeft: "5px",
                                      marginRight: "5px",
                                    }}
                                  />
                                </React.Fragment>
                              );
                            }
                          )}
                        {!data.interviewerDetails && (
                          <span style={{ color: "red" }}>No Data</span>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {data.interviewerDetails &&
                          data.interviewerDetails.roleTypeAllowed.map(
                            (value, index, array) => {
                              if (array.length - 1 === index) {
                                return (
                                  <React.Fragment>
                                    <Chip
                                      label={value}
                                      style={{
                                        backgroundColor: "#fe414d",
                                        color: "white",
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                      }}
                                    />
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                        {!data.interviewerDetails && (
                          <span style={{ color: "red" }}>N/A</span>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {data.totalInterviewsCount}
                      </TableCell>
                      <TableCell align="left">{data.weekdayCount}</TableCell>
                      <TableCell align="left">{data.weekendCount}</TableCell>
                      <TableCell align="left">
                        {data.Qualified ? data.Qualified : 0}
                      </TableCell>
                      <TableCell align="left">
                        {data.Reiteration ? data.Reiteration : 0}
                      </TableCell>
                      <TableCell align="left" style={{ cursor: "pointer" }}>
                        {data.Disqualified ? data.Disqualified : 0}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {displayingData.length === 0 && (
              <div
                style={{ textAlign: "center", fontSize: "24px", width: "100%" }}
              >
                <h4>No Result Found</h4>
              </div>
            )}
            <TablePagination
              rowsPerPageOptions={[5, 8]}
              component="div"
              count={displayingData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </React.Fragment>
      )}
      {isLoading && <Loader />}
    </React.Fragment>
  );
};

export default PanelReport;
