import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useSelector } from "react-redux";
import PanelistDetailsDump from './PanelistDetailsDump'
import axios from 'axios';
import { proxy } from '../../proxy';

const PanelDump = (props) => {
    const { user } = useAuthenticator((context) => [context.user]);
    const [data, setData] = useState([]);
    const [region, setRegion] = useState("");
    const [username, setUsername] = useState("");
    const profile = useSelector((state) => {
        return state.profile;
      });
      const presentProfile = user.attributes.profile;
      const ableToSwitch = user.attributes['custom:switch'];

    useEffect(()=>{
        window.scrollTo(0,0)

        if(ableToSwitch){
            axios
            .get(`${proxy}/api/interviewerprofile/${profile}`, {
              withCredentials: true,
              headers: {
                Authorization: user.signInUserSession.idToken.jwtToken,
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
              },
            })
            .then((res) => {
              console.log("Profile Data", res.data.result);
              setData(res.data.result);
      
              const extractedRegion = res.data.result.find(
                (item) => item.username === username
              );
              if (extractedRegion) {
                setRegion(extractedRegion.region);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          } else {
            axios
            .get(`${proxy}/api/interviewerprofile/${presentProfile}`, {
              withCredentials: true,
              headers: {
                Authorization: user.signInUserSession.idToken.jwtToken,
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
              },
            })
            .then((res) => {
              console.log("Profile Data", res.data.result);
              setData(res.data.result);
      
              const extractedRegion = res.data.result.find(
                (item) => item.username === username
              );
              if (extractedRegion) {
                setRegion(extractedRegion.region);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          }
    },[username, profile])

    return(
        <div>
            <PanelistDetailsDump dataDump = {data}></PanelistDetailsDump>
        </div>
    )
}


export default PanelDump;