import React, { useState } from "react";
import Section from "./Section";
// import TextField from "@mui/material/TextField";
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddIcon from '@mui/icons-material/Add';
import axios from "axios"
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Fab from '@mui/material/Fab';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Loader from "./Loader";
import { proxy } from "../proxy";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
// import { Auth } from "aws-amplify";
function SectionsDisplayer(){
    const { user} = useAuthenticator((context) => [context.user]);
    const [isLoading,setisLoading]=useState(true);   
    const [data,setData]=useState([]);
    const profile= useSelector((state) => {
        return state.profile;
      });
    const ableToSwitch = user.attributes['custom:switch'];
    const presentProfile = user.attributes.profile;

    useEffect(() => {
        window.scrollTo(0,0)
        if (ableToSwitch) {
        axios.get(`${proxy}/api/section/${profile}`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              'Authorization':user.signInUserSession.idToken.jwtToken,
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {
            setisLoading(false);
              setData(res.data.result)
          }).catch((error)=>{
            setisLoading(false);
          });
        } else {
            axios.get(`${proxy}/api/section/${presentProfile}`, {
                withCredentials: true,
                headers: {
                  Accept: "application/json",
                  'Authorization':user.signInUserSession.idToken.jwtToken,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Credentials": true,
                },
              }).then((res) => {
                setisLoading(false);
                  setData(res.data.result)
              }).catch((error)=>{
                setisLoading(false);
              });
        }
 // eslint-disable-next-line
      }, [profile]);
    const sectionTiles=data.map(({_id,section,subSections},index)=>{
        return (<Section key={index} section ={section } subSections={subSections} mongoid={_id}/>)
    })

    return(
        <div>
            {isLoading && <Loader/>}
            {!isLoading && 
            <div className="sections-div">
                <div className="sections-header" style={{height:"50px",background:"#fe414d"}}>
                    <div className="sections-title-header">
                        <p style={{color:"white"}}>Sections and Subsections </p>
                    </div>
                </div>

                <div className="sections-tiles-displayer">{sectionTiles}</div>
                
            </div>}
            <Link to="/addsection" style={{textDecoration:"none",color:"white",backgroundColor:"#aeea00"}}>
                <Tooltip title="Add Section">
                    <Fab backgroundColor="#aeea00" aria-label="add" style={  {position: 'fixed',bottom:16,right:16,backgroundColor:"#000"}}>
                            <AddIcon style={{color:"white"}}/>    
                    </Fab>
                </Tooltip>
            </Link>
        </div>

    )
    
}


export default SectionsDisplayer;