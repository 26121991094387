
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
const BlackButton = styled(Button)(({ theme }) => ({
    color:grey[50] ,
    backgroundColor: grey[900],
    '&:hover': {
      backgroundColor: grey[900],
    },
    marginTop:'20px',
    marginBottom:'20px'
  }));
  export default BlackButton;
  