
import {React} from 'react';
import '../styles/CreateFormRow.css';
import InterviewerFormColumn from './InterviewerFormColumn';


function InterviewerFormRowView(props){

    return (
        <div>
            <div className="create-form-row">
                <div className="create-form-row-title" style={{background:"#fe414d",color:"#fff"}}>
                    <h3>{props.data.sectionName}</h3>
                </div>
                <div className='create-form-row-columns-box'>
                    <div className='create-form-column' style={{borderRight:"0px solid black"}}>
                        <div className='create-form-column-heading-box' style={{background:"#F9F9F9"}}>
                            <p style={{color:"black"}}>Sub Section</p>
                        </div>
                        <div className='create-form-column-content-box' style={{textAlign:"center",background:"#F9F9F9"}}>
                            <p style={{fontSize:"large"}}>{props.data.subSectionName}</p>
                        </div>
                    </div>
                    <InterviewerFormColumn key={"0"} nameOfColumn={"Probe Areas"} expectations={props.data.probeAreas} isPresent={true}/>
                    <InterviewerFormColumn key ={"1"} nameOfColumn={props.data.roleName} expectations={props.data.expectations} isPresent={true}/>
                    <div className='create-form-column'>
                        <div className='create-form-column-heading-box'>
                            <p style={{color:"black",border:"0px solid black"}}>Marks</p>
                        </div>
                        <div className='create-form-column-content-box'style={{textAlign:"center",padding:"5px"}}>
                            {props.data.marks}
                        </div>

                    </div>
                    {props.data.recommendedProbeAreas && 
                    <div className="create-form-column">
                    <div className="create-form-column-heading-box">
                        <p style={{ color: "black", border: "0px solid black" }}>
                            RPA Feedback
                        </p>
                    </div>
                    <div
                        className="create-form-column-content-box"
                        style={{ textAlign: "center", padding: "5px" }}
                        >
                        {Object.entries(props.data.recommendedProbeAreas).map(
                            ([key, value]) => {
                            return (
                                <div className="rpa-content-wrapper">
                                <div className="rpa-key">{key}</div>:
                                <div className="rpa-value">{value}</div>
                                </div>
                            );
                            }
                        )}
                    </div>
               </div>
                    }
                    
                   <div className="create-form-column">
                        <div
                        className="create-form-column-heading-box"
                        style={{
                            borderBottom: "1px solid black",
                            borderRight: "0px solid black",
                        }}
                        >
                        <p style={{ color: "black", border: "0px solid black" }}>
                            Additional Comments
                        </p>
                        </div>
                        <div
                        className="create-form-column-content-box"
                        style={{
                            border: "0px solid black",
                            padding: "5px",
                            textAlign: "center",
                        }}
                        >
                        {props.data.remarks}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default InterviewerFormRowView;