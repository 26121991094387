import { useAuthenticator } from '@aws-amplify/ui-react';
import "../styles/Template.css"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useState,useEffect } from "react";
import {useDispatch} from 'react-redux';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TemplateStaticRow from "./TemplateStaticRow.jsx";
import { addStaticComponent, changeDescription } from "../features/templateSlice";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import DoneIcon from '@mui/icons-material/Done';
// import { Link } from "react-router-dom";

import { changeName } from "../features/templateSlice";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { proxy } from "../proxy";
import Loader from './Loader';
function TemplateStaticComponents(props) {
    const { user} = useAuthenticator((context) => [context.user]);
    const dispatch=useDispatch();
    const history=useHistory();
    const [currSectionIndex,setcurrSectionIndex]=useState(-1);
    const [section, setSection] = useState('');
    const [subSection, setSubSection] = useState('');
    const [editTitle, setEdittitle] = useState(false);
    const [editDescription, setEditDescription] = useState(false);
    const templatetitle=useSelector((store)=>{
        return store.template.templateName;
    })
    const templatedescription=useSelector((store)=>{
        return store.template.templateDescription;
    })
    const [title,setTitle]=useState(templatetitle)
    const [description,setDescription]=useState(templatedescription)
    const [isLoading,setisLoading]=useState(true);   
    const [data,setData]=useState([]);
    // const [subsectiontiles,setSubSectionTiles]=useState([])
    const profile= useSelector((state) => {
        return state.profile;
      });
    const presentProfile = user.attributes.profile;
    const ableToSwitch = user.attributes['custom:switch'];

    useEffect(() => {
        if(ableToSwitch){
            axios.get(`${proxy}/api/template/${profile}`, {
                withCredentials: true,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  'Authorization':user.signInUserSession.idToken.jwtToken,
                  "Access-Control-Allow-Credentials": true,
                },
              }).then((res) => {
                    setisLoading(false);
    
                //   console.log("result",res.data.result);
                  setData(res.data.result)
                //   console.log("data",data)
              }).catch((error)=>{
                setisLoading(false);
    
                alert("error")
              });
            } else {
                axios.get(`${proxy}/api/template/${presentProfile}`, {
                    withCredentials: true,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      'Authorization':user.signInUserSession.idToken.jwtToken,
                      "Access-Control-Allow-Credentials": true,
                    },
                  }).then((res) => {
                        setisLoading(false);
        
                    //   console.log("result",res.data.result);
                      setData(res.data.result)
                    //   console.log("data",data)
                  }).catch((error)=>{
                    setisLoading(false);
        
                    alert("error")
                  });
            }
      }, [profile]);      
    const handleChangeSection = (event) => {
        setSection(event.target.value);
        setcurrSectionIndex(JSON.parse(event.target.value).sectionIndex)
        // dispatch(changeSection({'section':event.target.value}))
      };
      const handleChangeSubSection = (event) => {
        setSubSection(event.target.value);
        // dispatch(changeSection({'section':event.target.value}))
      };
      const selectedTiles=useSelector((store)=>{
        // console.log(store.adminform.rows)
        return (store.template.template.map(({sectionId,sectionName,subSectionsData,weightage},index) => {
                return(
                    <TemplateStaticRow key={index} section={sectionName} sectionIndex={index} subSectionsData={subSectionsData} weightage={weightage}/>
                )
            }))
    })
    const validation=useSelector((store)=>{
        let totalscore=0.0;
        let valid=true;
        for(let i=0;i<store.template.template.length;i++){
            if(store.template.template[i].weightage<=0.0 ||store.template.template[i].weightage>100.0  ){
                valid=false;
            }
            totalscore+=Number(store.template.template[i].weightage);
        }
        return {"totalScore":totalscore,"isValid":valid}
    })
    return(
        <div className="template-wrapper">
            {/* <div className="block-buttons-div" style={{width:"80%",textAlign:"center",justifyContent:"center",height:"50px"}}>
                <h4>Template Form</h4>
            </div> */}
            {isLoading && <Loader/>}
            {!isLoading && 
            <div className="template-static-box" style={{marginTop:"30px"}}>
                <div className="template-title" style={{color:'rgb(254, 65, 77)'}}>
                    {!editTitle &&  <div className="template-title-box"><p>{title}</p></div>}
                    {!editTitle &&  <EditIcon className="template-edit-icon" size='large' onClick={()=>{setEdittitle(state => !state)}}></EditIcon>}    
                    {editTitle && <TextField className="template-title-box" onChange={(event)=>setTitle(event.target.value)} style={{margin:"auto"}}/>}
                    {editTitle &&  <DoneIcon className="template-edit-icon" size='large' onClick={()=>{setEdittitle(state => !state);dispatch(changeName({"templateName":title}))}}></DoneIcon>}    
                </div>
                <div className="template-title" style={{color:'rgb(254, 65, 77)'}}>
                    {!editDescription &&  <div className="template-title-box"><p>{description}</p></div>}
                    {!editDescription &&  <DescriptionIcon className="template-edit-icon" size='large' onClick={()=>{setEditDescription(state => !state)}}></DescriptionIcon>}    
                    {editDescription && <TextField className="template-title-box" onChange={(event)=>setDescription(event.target.value)} style={{margin:"auto"}}/>}
                    {editDescription &&  <DoneIcon className="template-edit-icon" size='large' onClick={()=>{setEditDescription(state => !state);dispatch(changeDescription({"templateDescription":description}))}}></DoneIcon>}    
                </div>
                <div className="template-static-header" >Add Your Blocks Here..</div>
                    <div className="template-static-selectors" >
                        <Box sx={{ minWidth: 250 }}>
                            <FormControl fullWidth>
                                <InputLabel id="section">Section</InputLabel>
                                <Select
                                    value={section}
                                    label="Age"
                                    onChange={handleChangeSection}
                                >
                                    {data.map(({sectionId,sectionName,subSectionsData},index)=>{
                                        return(<MenuItem key={index} value={JSON.stringify({"sectionIndex":index,"sectionId":sectionId,"sectionName":sectionName})}>{sectionName}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ minWidth: 250 }}>
                            <FormControl fullWidth>
                                <InputLabel id="section">Sub Section</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={subSection}
                                    label="Age"
                                    onChange={handleChangeSubSection}
                                >
                                    { currSectionIndex!==-1 &&  section!=={} && data[currSectionIndex].subSectionsData.map(({subSectionId,subSectionName},index)=>{
                                    return(<MenuItem value={JSON.stringify({"subSectionId":subSectionId,"subSectionName":subSectionName})}>{subSectionName}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Box>

                        <Button className="button-hover" variant="contained" startIcon={<AddIcon />} onClick={()=>{
                            dispatch(addStaticComponent({sectionData:JSON.parse(section),subSectionData:JSON.parse(subSection)}))
                            setSection({});
                            setSubSection({});
                            setcurrSectionIndex(-1)
                            // setSubSectionTiles([])
                            
                        }} style={{backgroundColor:"#000"}}>
                        Add Component
                        </Button>
                    </div>
                    <table className="template-table">
                        <thead>
                            <th>Section</th>
                            <th>Subsection</th>
                            <th style={{width:"350px"}}>Weightage</th>
                            <th></th>
                        </thead>
                        <tbody>
                            {selectedTiles.length===0 && <tr > 
                                <td>No section selected </td> 
                                <td>No subsection selected </td>
                                <td>No weightage is assigned</td>
                            </tr>}
                            {selectedTiles}
                        </tbody>
                    </table>
                <Button className="button-hover" variant="contained" style={{margin:"auto",width:"150px",backgroundColor:"#000"}} onClick={()=>{
                    // console.log(validation)
                    if(templatetitle=="" || templatetitle=="Add Template Name"){
                        alert('Title Not Given')
                        return
                    }
                    if(templatedescription=="" || templatedescription=="Add Template Description"){
                        alert('Description Not Given')
                        return
                    }

                    if(Number(validation.totalScore)===100 && validation.isValid){
                        history.push(`/form/${props.templatemongoid}`)
                    }
                    else{
                        alert("total score should be equal to 100 and positive")
                    }
                }} >
                Review
                </Button>
                <p>* Click on the review button to view the details.</p>
                <p>* Form will be submitted after it gets reviewed.</p>
            </div>}
        </div>

    )
}

export default TemplateStaticComponents;