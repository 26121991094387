import * as React from "react";
import { useEffect, useState } from "react";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import "./Reports.css";
import { Grid, Tooltip } from "@mui/material";
import { proxy } from "../../proxy";
import { useAuthenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import PinIcon from "@mui/icons-material/Pin";
import PieChartIcon from "@mui/icons-material/PieChart";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import BlackButton from "../BlackButton";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { motion } from "framer-motion";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import TopContributors from "./TopContributors";
import Loader from "../Loader";
import { useSelector } from "react-redux";

function Reports() {
  let date = new Date();
  date.setDate(date.getDate() - 7);

  const { user } = useAuthenticator((context) => [context.user]);
  const [isLoading, setisLoading] = useState(false);
  const [statusData, setData] = useState(null);
  const [startValue, setStartValue] = useState(date);
  const [endValue, setEndValue] = useState(new Date());
  const [toDisplay, setToDisplay] = useState(false);
  const profile= useSelector((state) => {
    return state.profile;
  });
  const presentProfile = user.attributes.profile;
  const ableToSwitch = user.attributes['custom:switch'];

  useEffect(() => {
    window.scrollTo(0, 0);
    setisLoading(true);
    getReportsData();
  }, [profile]);

  const getReportsData = async () => {
    setisLoading(true);
    if(ableToSwitch){
      try {
        const res = await axios.post(
          `${proxy}/api/reports`,
          {
            startDate: startValue,
            endDate: endValue,
            profile: profile,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        );
        setisLoading();
        setData(res.data.results);
        console.log(res.data.results);
      } catch (error) {
        console.log(error);
      }
    }else{
      try {
        const res = await axios.post(
          `${proxy}/api/reports`,
          {
            startDate: startValue,
            endDate: endValue,
            profile: presentProfile,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        );
        setisLoading();
        setData(res.data.results);
        console.log(res.data.results);
      } catch (error) {
        console.log(error);
      }
    }
    
  };

  return (
    <div style={{ width: "100%", height: "fit-content", margin: "auto" }}>
      <div>
        <div
          className="sections-header"
          style={{ height: "50px", background: "#fe414d" }}
        >
          <div className="sections-title-header">
            <p style={{ color: "white" }}>
              Performance Overview Dashboard View
            </p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {!toDisplay && (
            <Tooltip title="Open Filters">
              <motion.div
                initial={{ y: -10 }}
                animate={{ y: 0 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowDownIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
          {toDisplay && (
            <Tooltip title="Close Filter">
              <motion.div
                initial={{ y: 0 }}
                animate={{ y: -10 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowUpIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
        </div>

        {toDisplay && (
          <motion.div
            key="box"
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ type: "spring", duration: 2.5 }}
            className="box"
          >
            <div style={{ padding: "5px", width: "100%", marginLeft: "35%" }}>
              <Grid container spacing={1} style={{ justifyContent: "center" }}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      label="Start Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="startDate"
                          sx={{ width: 150, marginRight: 2 }}
                        />
                      )}
                      value={startValue}
                      onChange={(newValue) => {
                        setStartValue(new Date(newValue).toISOString());
                      }}
                    />
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      minDate={startValue}
                      label="End Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="endDate"
                          sx={{ width: 150 }}
                        />
                      )}
                      value={endValue}
                      onChange={(newValue) => {
                        setEndValue(new Date(newValue).toISOString());
                      }}
                    />
                  </LocalizationProvider>
                  <BlackButton
                    style={{ marginLeft: "6%" }}
                    onClick={getReportsData}
                  >
                    Apply
                  </BlackButton>
                </Grid>
              </Grid>
            </div>
          </motion.div>
        )}

        {isLoading && (
          <div style={{ height: "100%", margin: "auto" }}>
            <Loader />
          </div>
        )}

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              {!isLoading && (
                <div style={{ margin: "20px" }}>
                  <div style={{ width: "100%" }}>
                    <h2>
                      <PinIcon
                        style={{ color: "red", fontSize: "30px" }}
                      ></PinIcon>{" "}
                      KPI Index
                    </h2>
                  </div>
                  {statusData && (
                    <div className="chart-row">
                      <motion.div
                        whileHover={{
                          scale: 1.1,
                          boxShadow: "0px 0px 8px rgb(229, 228, 226)",
                        }}
                      >
                        <div style={{ width: "15%", cursor: "pointer" }}>
                          <Box sx={{ width: 170 }}>
                            <Card
                              variant="outlined"
                              style={{
                                backgroundColor: "#e6ffe6",
                                height: "130px",
                              }}
                            >
                              <CardContent>
                                <Typography
                                  sx={{
                                    fontSize: 20,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  Qualified
                                </Typography>
                                <Typography
                                  variant="h5"
                                  component="div"
                                  sx={{
                                    fontSize: 50,
                                    textAlign: "center",
                                    color: "green",
                                  }}
                                >
                                  {statusData.interviewKpi[1]}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Box>
                        </div>
                      </motion.div>

                      <motion.div
                        whileHover={{
                          scale: 1.1,
                          boxShadow: "0px 0px 8px rgb(229, 228, 226)",
                        }}
                      >
                        <div style={{ width: "15%", cursor: "pointer" }}>
                          <Box sx={{ width: 170 }}>
                            <Card
                              variant="outlined"
                              style={{
                                backgroundColor: "#ffe6e6",
                                height: "130px",
                              }}
                            >
                              <CardContent>
                                <Typography
                                  sx={{
                                    fontSize: 20,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  Disqualified
                                </Typography>
                                <Typography
                                  variant="h5"
                                  component="div"
                                  sx={{
                                    fontSize: 50,
                                    textAlign: "center",
                                    color: "red",
                                  }}
                                >
                                  {statusData.interviewKpi[2]}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Box>
                        </div>
                      </motion.div>

                      <motion.div
                        whileHover={{
                          scale: 1.1,
                          boxShadow: "0px 0px 8px rgb(229, 228, 226)",
                        }}
                      >
                        <div style={{ width: "15%", cursor: "pointer" }}>
                          <Box sx={{ width: 170 }}>
                            <Card
                              variant="outlined"
                              style={{
                                backgroundColor: "#fff2e6",
                                height: "130px",
                              }}
                            >
                              <CardContent>
                                <Typography
                                  sx={{
                                    fontSize: 20,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  Reiterations
                                </Typography>
                                <Typography
                                  variant="h5"
                                  component="div"
                                  sx={{
                                    fontSize: 50,
                                    textAlign: "center",
                                    color: "orange",
                                  }}
                                >
                                  {statusData.interviewKpi[3]}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Box>
                        </div>
                      </motion.div>

                      <motion.div
                        whileHover={{
                          scale: 1.1,
                          boxShadow: "0px 0px 8px rgb(229, 228, 226)",
                        }}
                      >
                        <div style={{ width: "15%", cursor: "pointer" }}>
                          <Box sx={{ width: 200 }}>
                            <Card
                              variant="outlined"
                              style={{ height: "130px" }}
                            >
                              <CardContent>
                                <Typography
                                  sx={{
                                    fontSize: 20,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  Interviews Taken
                                </Typography>
                                <Typography
                                  variant="h5"
                                  component="div"
                                  sx={{
                                    fontSize: 50,
                                    textAlign: "center",
                                    color: "grey",
                                  }}
                                >
                                  {statusData.interviewKpi[0]}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Box>
                        </div>
                      </motion.div>
                    </div>
                  )}
                  <div style={{ width: "100%" }}>
                    <h2>
                      <PieChartIcon
                        style={{ color: "red", fontSize: "30px" }}
                      ></PieChartIcon>{" "}
                      Rolewise Statistics
                    </h2>
                  </div>

                  <div className="chart-row">
                    {statusData &&
                      statusData.roles.map(({ role, roleStatus }) => {
                        return (
                          <div className="chart-box" style={{ width: "250px" }}>
                            <PieChart
                              chartData={{
                                labels: [
                                  "Qualified",
                                  "DisQualified",
                                  "Reiteration",
                                ],
                                datasets: [
                                  {
                                    label: `Status of candidate for ${role}`,
                                    data: [
                                      roleStatus[0].qualified,
                                      roleStatus[1].rejected,
                                      roleStatus[2].reiteration,
                                    ],
                                    backgroundColor: [
                                      "#4feb34",
                                      "#ed0909",
                                      "#faa005",
                                    ],
                                    borderColor: "black",
                                    borderWidth: 1,
                                  },
                                ],
                              }}
                            />
                            <h5
                              style={{
                                textAlign: "center",
                                color: "grey",
                                fontSize: "small",
                              }}
                            >{`${role}`}</h5>
                          </div>
                        );
                      })}
                  </div>
                  <div style={{ width: "100%" }}>
                    <h2>
                      <StackedBarChartIcon
                        style={{ color: "red", fontSize: "30px" }}
                      ></StackedBarChartIcon>{" "}
                      Chart Distribution
                    </h2>
                  </div>
                  <div className="chart-row">
                    <div className="chart-box" style={{ width: "40%" }}>
                      {statusData && (
                        <BarChart
                          chartData={{
                            labels: [
                              "Qualified",
                              "DisQualified",
                              "Reiteration",
                            ],
                            datasets: [
                              {
                                label: "Status of Candidates",
                                data: [
                                  statusData.allStatus[0].qualified,
                                  statusData.allStatus[1].rejected,
                                  statusData.allStatus[2].reiteration,
                                ],
                                backgroundColor: [
                                  "#4feb34",
                                  "#ed0909",
                                  "#faa005",
                                ],
                                borderColor: "black",
                                borderWidth: 1,
                                borderRadius: 5,
                                barThickness: 50,
                              },
                            ],
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Grid>

            <Grid item xs={4}>
              {!isLoading && (
                <React.Fragment>
                  <div style={{ width: "100%" }}>
                    <h2>
                      <StackedBarChartIcon
                        style={{ color: "red", fontSize: "30px" }}
                      ></StackedBarChartIcon>{" "}
                      Top Contributor
                    </h2>
                  </div>
                  <TopContributors startDate={startValue} endDate={endValue} />
                </React.Fragment>
              )}
              {isLoading && <Loader />}
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
export default Reports;
