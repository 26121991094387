import * as React from 'react';
import BlackButton from '../BlackButton';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Tooltip } from '@mui/material';
import DownloadingIcon from '@mui/icons-material/Downloading';

const PanelistDetailsDump = ({dataDump}) => {

    const exportPanelData = (displayData) => {
        console.log("displayData", displayData);
        try {
            const filteredData = displayData.map(item => {
                const {
                    fullName,
                    username,
                    positionTitle,
                    active,
                    interviewerExpertise,
                    region,
                    roleTypeAllowed,
                    isAdmin,
                    isHiringManager,
                    isInterviewer,
                    isPhoneInterviewer,
                    isCXO,
                    lastLoginDateTime,
                    onboardingDate,
                    empaneledDate,
                    disempaneledDate,
                    updatedStatus
                } = item
    
                return {
                  FullName: fullName || "", 
                  InterviewerId: username || "", 
                  PositionTitle: positionTitle || "",
                  PanelActiveStatus: active || "",
                  InterviewerExpertise: interviewerExpertise ? interviewerExpertise.join(', ') : "",
                  PanelRegion: region,
                  AllowedRoleType: roleTypeAllowed ? roleTypeAllowed.join(', ') : "",
                  IsAdmin: isAdmin, 
                  IsHiringManager: isHiringManager || "", 
                  IsInterviewer: isInterviewer || "", 
                  IsPhoneInterviewer: isPhoneInterviewer || "",
                  IsCXO: isCXO || "",
                  LastLoginDateTime: lastLoginDateTime || "", 
                  OnboardingDate: onboardingDate || "",
                  EmpaneledDate: empaneledDate || "",
                  DisempaneledDate: disempaneledDate || "",
                  PanelStatus: updatedStatus || ""
              };
            });
    
            const ws = XLSX.utils.json_to_sheet(filteredData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const currentDateTimeString = getCurrentDateTimeString();
            const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            FileSaver.saveAs(data, `Panel_Data_Dump-${currentDateTimeString}.xlsx`);
        } catch (error) {
            alert("Download Status: Error Downloading");
        }
    };
    const getCurrentDateTimeString = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    };

    return(
        <React.Fragment>
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Tooltip title="Click to Download">
                        <BlackButton endIcon={<DownloadingIcon />} style={{ width: '300px', height: '50px', fontSize: '1.2em', padding: '10px 0 10px 0' }}
                        onClick={() => exportPanelData(dataDump)}
                        >
                        Download Panel Dump
                        </BlackButton>    
                    </Tooltip>     
                </div>
            </div>
        </React.Fragment>
    )

}

export default PanelistDetailsDump;