import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CreateIcon from '@mui/icons-material/Create';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import InsightsIcon from '@mui/icons-material/Insights';
import Search from '@mui/icons-material/Search';
import ArticleIcon from '@mui/icons-material/Article';
// import WidgetsIcon from '@mui/icons-material/Widgets';
import PeopleIcon from '@mui/icons-material/People';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import PieChart from '@mui/icons-material/PieChart';
import { motion } from 'framer-motion';

// import { ListItemAvatar } from '@mui/material';
export default function TemporaryDrawer() {
const iconColor="#fe414d"
const { user} = useAuthenticator((context) => [context.user]);
let roles=[]
if(user && user.signInUserSession.idToken && user.signInUserSession.idToken.payload['cognito:groups']){
        roles=user.signInUserSession.idToken.payload['cognito:groups'];
}
let anchor='left';
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const list = 
  <Box
  sx={{ width: 250}}
  role="responsive"
  onClick={toggleDrawer(false)}
  onKeyDown={toggleDrawer(false)}
  
>
    <List>
            <Link to="/" style={{ textDecoration: 'none',color:"black" }}>
                    <motion.li whileHover={{scale:1.05}}>
                    <ListItem disablePadding>
                            <ListItemButton>
                                    <ListItemIcon>
                                            <HomeIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={"Home"} />
                            </ListItemButton>
                    </ListItem>
                    </motion.li>
            </Link>
    <Divider/>
    </List>
{roles.includes("Interviewer") && 
<List style={{color:"black"}}>
    <motion.li whileHover={{scale:1.05}}>
    <ListItem disablePadding>
            
            <ListItemButton>
                    <ListItemIcon >
                            <PeopleIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Interviewer"} />
            </ListItemButton>
            
    </ListItem>
    </motion.li>
    <Divider/>
    <Link to="/candidatedetails" style={{ textDecoration: 'none',color:"black" }}>
    <motion.li whileHover={{scale:1.05}}>
        <ListItem disablePadding>
        
                <ListItemButton>
                    <ListItemIcon>
                            <CreateIcon style={{color:iconColor}}/>
                    </ListItemIcon>
                        <ListItemText primary={"Domain Interview Form"} />
                </ListItemButton>
                
        </ListItem>
        </motion.li>
    </Link>
    <Link to="/filledforms" style={{ textDecoration: 'none',color:"black" }}>
    <motion.li whileHover={{scale:1.05}}>
        <ListItem disablePadding>
        
                <ListItemButton>
                    <ListItemIcon style={{color:iconColor}}>
                            <Search/>
                    </ListItemIcon>
                        <ListItemText primary={"Search Domain Forms"} />
                </ListItemButton>
                
        </ListItem>
        </motion.li>
    </Link>

</List>}
{roles.includes("CXO")  && <Divider />}
{roles.includes("CXO") && 
<List style={{color:"black"}}>
    <motion.li whileHover={{scale:1.05}}>
    <ListItem disablePadding>
    
            <ListItemButton>
                    <ListItemIcon >
                            <PeopleIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"CXO"} />
            </ListItemButton>
            
    </ListItem>
    </motion.li>
    <Divider/>
    <Link to="/reports" style={{ textDecoration: 'none',color:"black" }}>
    <motion.li whileHover={{scale:1.05}}>
    <ListItem disablePadding>
    
            <ListItemButton>
                    <ListItemIcon>
                            <PieChart style={{color:iconColor}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Reports"} />
            </ListItemButton>
            
    </ListItem>
    </motion.li>
    </Link>
    <Link to="/filledforms/cxo" style={{ textDecoration: 'none',color:"black" }}>
    <motion.li whileHover={{scale:1.05}}>
    <ListItem disablePadding>
    
            <ListItemButton>
                    <ListItemIcon style={{color:iconColor}}>
                            <Search/>
                    </ListItemIcon>
                    <ListItemText primary={"Search Forms"} />
            </ListItemButton>
            
    </ListItem>
    </motion.li>
    </Link>

    </List>}
{roles.includes("Interviewer") && <Divider />}
  {roles.includes("Admin") &&
  <List>
    <motion.li whileHover={{scale:1.05}}>
    <ListItem disablePadding>
    
            <ListItemButton>
                    <ListItemIcon>
                            <PeopleIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Admin"} />
            </ListItemButton>
            
    </ListItem>
    </motion.li>
            <Divider/>
        <Link to="/sections" style={{ textDecoration: 'none',color:"black" }}>
        <motion.li whileHover={{scale:1.05}}>
            <ListItem disablePadding>
            
                    <ListItemButton >
                            <ListItemIcon style={{color:iconColor}}>
                                    <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"Sections"} />
                    </ListItemButton>
                    
            </ListItem>
            </motion.li>
        </Link>
        <Link to="/templates" style={{ textDecoration: 'none',color:"black" }}>
        <motion.li whileHover={{scale:1.05}}>
            <ListItem disablePadding>
            
                    <ListItemButton>
                            <ListItemIcon style={{color:iconColor}}>
                                    <ArticleIcon/>
                            </ListItemIcon>
                        <ListItemText primary={"Templates"} />
                    </ListItemButton>
                    
            </ListItem>
            </motion.li>
        </Link>
        <Link to="/roles" style={{ textDecoration: 'none',color:"black" }}>
        <motion.li whileHover={{scale:1.05}}>
            <ListItem disablePadding>
            
                    <ListItemButton>
                            <ListItemIcon style={{color:iconColor}}>
                                    <EngineeringIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"Roles"} />
                    </ListItemButton>
                    
            </ListItem>
            </motion.li>
        </Link>
    </List>}
</Box>
 

  return (
    <div >
        <React.Fragment key={anchor}>
          <MenuIcon onMouseEnter={toggleDrawer(true)} onMouseLeave={toggleDrawer(true)} style={{height:"32px"}}></MenuIcon>
          <Drawer
            anchor={anchor}
            open={state}
            onClose={toggleDrawer(false)}
                      >
            {list}
          </Drawer>
        </React.Fragment>
    </div>
  );
}
