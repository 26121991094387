import "../styles/Section.css"
import SubSection from "./SubSection"
import Edit from "@mui/icons-material/Edit"
import { Link } from "react-router-dom"
import { motion } from "framer-motion";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from "react"
import { Tooltip } from "@mui/material"
import axios from "axios";
import { proxy } from "../proxy";
import { useAuthenticator } from '@aws-amplify/ui-react';
import IconButton from '@mui/material/IconButton';

function Section(props){
    const [toDisplay,setToDisplay]=useState(false);
    const { user} = useAuthenticator((context) => [context.user]);
    const SubSectiontiles=props.subSections.map(({_id,subSection,blockId},index)=>{
        return (<SubSection  key={index} section={props.section} blockId={blockId} subSection={subSection} index={index} subSectionMongoId={_id} sectionMongoId={props.mongoid}/>)
    });
    const handleSectionDeletion = () => {
        // let x=window.confirm("Are You Sure You want to delete this Section ? \n Note : The section will only be deleted if subsections are empty.")
        axios.delete(`${proxy}/api/section/${props.mongoid}`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              'Authorization':user.signInUserSession.idToken.jwtToken,
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {
                //setIsLoading(false);
               console.log(res.data);
              alert("Section Deleted")
              window.location.reload();
          }).catch((error)=>{
           // setIsLoading(false);
            alert("error")
            console.log(error);
          });
          //setIsLoading(true);  
    };
    return(
        <div style={{width:"100%"}}>
            <motion.div whileHover={{scale:1.02}}>
            <div className="section-div">
                <div className="section-header" onClick={()=>{
                    setToDisplay(!toDisplay);
                }}>
                    <div style={{width:"fit-content",textAlign:"left"}}>
                        <p style={{fontWeight:"300"}}>Section: <b>{props.section}</b></p>
                    </div>
                    
                    <div className="section-header-icons">
  
                        <Link to={`/${props.section}/${props.mongoid}`} style={{textDecoration:"none",color:"#000"}}>
                            <Tooltip title="Edit Section">
                                <Edit className="icon-hover"/>
                            </Tooltip>
                        </Link>
                        <Tooltip title="Delete Section">
                        <IconButton style={{textDecoration:"none",color:"#000"}} aria-label="Delete a Section" onClick={handleSectionDeletion}>
                            <ClearIcon className="icon-hover"/>
                        </IconButton>    
                        </Tooltip>
                        <Link to={`/addsubsection/${props.section}/${props.mongoid}`} style={{textDecoration:"none",color:"#000"}}>
                            {/* <BlackButton  startIcon={<AddIcon/>} variant="contained" style={{width:"fit-content",padding:"5px",fontSize:"xx-small"}} className="button-colors"> ADD SubSection </BlackButton> */}
                            <Tooltip title="Add SubSection">
                                <AddIcon className="icon-hover"/>
                            </Tooltip>
                            
                        </Link>
                    </div>
                </div>
                {toDisplay && 
                <motion.div initial={{ y: -10 }} animate={{ y: 0 }} transition={{ type: "spring", stiffness: 100 }} className="subsections-div">
                    <div className="sub-sections-name">
                        Sub Sections
                    </div>
                    {SubSectiontiles}
                    {SubSectiontiles.length===0 && <div className="sub-sections-name" style={{height:"50px",fontSize:"large"}}> 
                        SubSections not found 
                    </div>}
                </motion.div>}
            </div>
            </motion.div>
        </div>

    )
}
// <div style={{width:"100%",margin:"auto",border:"1px solid red",display:"flex",flexDirection:"column",alignItems:"center"}}></div>

export default Section;