import * as React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../styles/UpdateInterviewerPage.css";
import BlackButton from "./BlackButton";
import { proxy } from "../proxy";
import { useHistory } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CreateIcon from "@mui/icons-material/Create";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import RemarkModal from "./Remarks";
import { tidy, distinct, filter } from "@tidyjs/tidy";
import GradingIcon from "@mui/icons-material/Grading";
import { Fab, Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import {
  TextField,
  FormControl,
  Typography,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
  Button,
} from "@mui/material";
import PanelistDetailsDump from '../Components/charts/PanelistDetailsDump'

function UpdateInterviewerPage(props) {
  const { user } = useAuthenticator((context) => [context.user]);
  const history = useHistory();
  const [roleData, setRoleData] = useState([]);
  const [role, setRole] = useState("");
  const [region, setRegion] = useState("");
  const regionMenuItems = ["IND", "EU", "NA"];
  const [allowedRoles, setAllowedRoles] = useState([]);
  const [username, setUsername] = useState("");
  const [active, setActive] = useState("false");
  const [fullName, setFullName] = useState("");
  const [status, setStatus] = useState("Certified");
  const [remark, setRemark] = useState("");
  const [expertise, setExpertise] = useState([]);
  const [data, setData] = useState([]);
  const [interviewers, setInterviewers] = useState([]);
  const [interviewer, setInterviewer] = useState();
  const [display, setDisplay] = React.useState(false);
  const [ifStatusChanged, setIfStatusChanged] = React.useState(true);
  const [header, setHeader] = React.useState(true);
  const [position, setPosition] = useState("");
  const [allRemarks, setAllRemarks] = useState([]);
  const [showRemarks, setRemarksDisplay] = useState(false);
  const profile = useSelector((state) => {
    return state.profile;
  });
  const presentProfile = user.attributes.profile;
  const ableToSwitch = user.attributes['custom:switch'];
  const [currentStatus, setCurrentStatus] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${proxy}/api/role`, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: user.signInUserSession.idToken.jwtToken,

          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        setRoleData(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });

    if(ableToSwitch){
      axios
      .get(`${proxy}/api/interviewerprofile/${profile}`, {
        withCredentials: true,
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        console.log("Profile Data", res.data.result);
        setData(res.data.result);

        const extractedRegion = res.data.result.find(
          (item) => item.username === username
        );
        if (extractedRegion) {
          setRegion(extractedRegion.region);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      axios
      .get(`${proxy}/api/interviewerprofile/${presentProfile}`, {
        withCredentials: true,
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        console.log("Profile Data", res.data.result);
        setData(res.data.result);

        const extractedRegion = res.data.result.find(
          (item) => item.username === username
        );
        if (extractedRegion) {
          setRegion(extractedRegion.region);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  
    if(ableToSwitch){
      axios
      .get(`${proxy}/api/interviewerList/${profile}`, {
        withCredentials: true,
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        setInterviewers(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      axios
      .get(`${proxy}/api/interviewerList/${presentProfile}`, {
        withCredentials: true,
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        setInterviewers(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
    }
    
  }, [username, profile]);

  const handleChangeRole = (event) => {
    setRole(event.target.value);
    if (event.target.value) {
      setErrors({
        ...errors,
        [event.target.name]: "",
      });
    }
  };
  const handleChangeUserName = (event, newvalue) => {
    setUsername(newvalue);
  };
  const handleOnChangeExpertise = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setExpertise([...expertise, value]);
    } else {
      setExpertise(expertise.filter((e) => e !== value));
    }
  };

  const handleChangeToggle = (event) => {
    setActive(event.target.value);
  };
  const handleChangeRegion = (event) => {
    setRegion(event.target.value);
  };
  const handleChangeRemarks = (event) => {
    setRemark(event.target.value);
  };
  const handleChangeStatus = (event) => {
    if (event.target.value != currentStatus) {
      setIfStatusChanged(false);
    } else {
      setIfStatusChanged(true);
    }
    setStatus(event.target.value);
  };
  const handleChangePosition = (event) => {
    setPosition(event.target.value);
    if (event.target.value) {
      setErrors({
        ...errors,
        [event.target.name]: "",
      });
    }
  };
  const handleform = () => {
    let d;
    d = tidy(
      data,
      filter((d) => d.username === username)
    );
    if (d[0].roleTypeAllowed) {
      let i = 0;
      let currentLevel = 0;
      let currentRole;
      setInterviewer(d[0]);

      while (i < d[0].roleTypeAllowed.length) {
        let lev = tidy(
          roleData,
          filter((item) => item.role === d[0].roleTypeAllowed[i])
        );
        console.log(lev);
        if (lev[0].level > currentLevel) {
          currentLevel = lev[0].level;
          currentRole = lev[0].role;
        }
        i++;
      }
      setRole(currentRole);
      setDisplay(true);
      setHeader(false);
      setPosition(d[0].positionTitle);
      setFullName(d[0].fullName);
      if (d[0].active) setActive("true");
      else setActive("false");
      setStatus(d[0].updatedStatus);
      setCurrentStatus(d[0].updatedStatus);
      setExpertise(d[0].interviewerExpertise);
      if (d[0].remarks) setAllRemarks(d[0].remarks);
    } else {
      setFullName(d[0].fullName);
      setDisplay(true);
      setHeader(false);
    }
  };
  const handleCheck = (skill) => {
    return expertise.some((item) => skill === item);
  };

  const [errors, setErrors] = useState({
    fullName: "",
    position: "",
    role: "",
  });
  const NAME_REGEX = /^[A-z0-9-_ .]{1,30}$/;

  const validateInput = () => {
    let isValid = true;
    let fullNameError = "";
    let positionError = "";
    let roleError = "";
    if (!NAME_REGEX.test(fullName)) {
      isValid = false;
      fullNameError = "Enter valid Full name";
    }
    if (!NAME_REGEX.test(role)) {
      isValid = false;
      roleError = "Select Highest Allowed Role Type";
    }
    if (!NAME_REGEX.test(position)) {
      isValid = false;
      positionError = "Select Position";
    }
    setErrors({
      fullName: fullNameError,
      position: positionError,
      role: roleError,
    });

    return isValid;
  };
  const handleCancel = () => {
    window.location.reload(true);
  };
  const handleChangeFullName = (event) => {
    setFullName(event.target.value);
    if (event.target.value) {
      setErrors({
        ...errors,
        [event.target.name]: "",
      });
    }
  };
  function onSubmit(e) {
    e.preventDefault();
    if (!validateInput()) {
      return;
    }
    var a;
    var v = remark;
    if (remark) {
      setAllRemarks([...allRemarks, v]);
    }
    if (active === "true") a = true;
    else a = false;
    let roles = [];
    let lev = tidy(
      roleData,
      filter((item) => item.role === role)
    );
    roleData.map(({ role, level }) => {
      if (level <= lev[0].level) {
        roles.push(role);
      }
    });
    let remarks = allRemarks;
    if (remark) {
      remarks.push(remark);
    }
    if(ableToSwitch){
      sendReportsData({
        interviewerName: username,
        fullName: fullName,
        interviewerExpertise: expertise,
        roleTypeAllowed: roles,
        updatedStatus: status,
        positionTitle: position,
        region: region,
        active: a,
        remark: remark,
        profile: profile,
        editedby: user.username,
        ifStatusChanged: ifStatusChanged,
      });
      return false;
    } else {
      sendReportsData({
        interviewerName: username,
        fullName: fullName,
        interviewerExpertise: expertise,
        roleTypeAllowed: roles,
        updatedStatus: status,
        positionTitle: position,
        region: region,
        active: a,
        remark: remark,
        profile: presentProfile,
        editedby: user.username,
        ifStatusChanged: ifStatusChanged,
      });
      return false;
    }
    
  }

  const sendReportsData = (data) => {
    axios
      .put(`${proxy}/api/interviewerprofile`, data, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        Swal.fire({
          title: "Successfully Uploaded",
          text: "Congratulations!",
          icon: "success",
          showCancelButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
          stopKeydownPropagation: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(true);
          }
        });
      })
      .catch((error) => {
        window.location.reload(true);
      });
  };

  return (
    <div>
      <div
        className="sections-header"
        style={{ background: "#fe414d", color: "#fff" }}
      >
        <div className="sections-title-header">
          <p style={{ color: "#fff" }}>Edit Interviewer Details </p>
        </div>
      </div>
      {header && (
        <div className="line">
          <div className="inline">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={interviewers}
              sx={{ width: 400 }}
              renderInput={(params) => (
                <TextField {...params} label="Interviewer" />
              )}
              onChange={handleChangeUserName}
            />
          </div>
          <div className="inline">
            <BlackButton onClick={handleform}>Edit</BlackButton>
          </div>
        </div>
      )}
      {header && (
        <div>
          <PanelistDetailsDump dataDump = {data}></PanelistDetailsDump>  
        </div>
      )}
      {display && (
        <div className="interviewer-form">
          <p className="interviewer-form-header">
            Interviewer Name: <b>{username}</b>{" "}
          </p>
          <div className="box">
            <div className="box-secondary">
              <form onSubmit={onSubmit}>
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    rowSpacing={0.5}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={6}>
                      <div className="interviewer-form-option-title">
                        <p>
                          <PeopleIcon
                            style={{ color: "red", marginRight: "5px" }}
                          ></PeopleIcon>
                          Full Name
                        </p>
                      </div>
                      <TextField
                        label="Interviewer Full Name"
                        onChange={handleChangeFullName}
                        placeholder="Enter Full Name"
                        variant="outlined"
                        value={fullName}
                        fullWidth
                        name="fullName"
                        error={Boolean(errors.fullName)}
                        helperText={errors.fullName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div className="interviewer-form-option-title">
                        <p>
                          <CreateIcon
                            style={{ color: "red", marginRight: "5px" }}
                          ></CreateIcon>
                          Career Stage
                        </p>
                      </div>
                      <FormControl fullWidth error={Boolean(errors.position)}>
                        <InputLabel id="demo-simple-select-label">
                          Choose Career Stage
                        </InputLabel>
                        <Select
                          label="Choose Career Stage"
                          name="position"
                          value={position}
                          onChange={handleChangePosition}
                        >
                          {roleData.map(({ _id, role }, index) => {
                            return (
                              <MenuItem key={index} value={role}>
                                {role}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>{errors.position}</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="interviewer-form-option-title">
                        <p>
                          <CalendarTodayIcon
                            style={{ color: "red", marginRight: "5px" }}
                          ></CalendarTodayIcon>
                          Expertise
                        </p>
                      </div>
                      <FormControl
                        fullWidth
                        sx={{ position: "flex", flexDirection: "row" }}
                      >
                        <div className="boxes">
                          <input
                            type="checkbox"
                            id="Azure"
                            defaultChecked={handleCheck("Azure")}
                            value="Azure"
                            onChange={handleOnChangeExpertise}
                          />
                          <label for="Azure">
                            <img
                              style={{
                                width: "35px",
                                height: "28px",
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                              src={require("../images/azure_icon.png")}
                              alt="logo"
                            />
                          </label>
                          <input
                            type="checkbox"
                            id="AWS"
                            defaultChecked={handleCheck("AWS")}
                            value="AWS"
                            onChange={handleOnChangeExpertise}
                          />
                          <label for="AWS">
                            <img
                              style={{
                                width: "40px",
                                height: "30px",
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                              src={require("../images/aws_icon.png")}
                              alt="logo"
                            />
                          </label>
                          <input
                            type="checkbox"
                            id="GCP"
                            defaultChecked={handleCheck("GCP")}
                            value="GCP"
                            onChange={handleOnChangeExpertise}
                          />
                          <label for="GCP">
                            <img
                              style={{
                                width: "40px",
                                height: "30px",
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                              src={require("../images/gcp_icon.png")}
                              alt="logo"
                            />
                          </label>
                        </div>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                      <div className="interviewer-form-option-title">
                        <p>
                          <CreateIcon
                            style={{ color: "red", marginRight: "5px" }}
                          ></CreateIcon>
                          Highest Allowed Role
                        </p>
                      </div>
                      <FormControl fullWidth error={Boolean(errors.role)}>
                        <InputLabel id="demo-simple-select-label">
                          Choose Role
                        </InputLabel>
                        <Select
                          label="Choose Role"
                          name="role"
                          value={role}
                          onChange={handleChangeRole}
                        >
                          {roleData.map(({ level, role }, index) => {
                            return (
                              <MenuItem key={index} value={role}>
                                {role}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>{errors.role}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="interviewer-form-option-title">
                        <p>
                          <CreateIcon
                            style={{ color: "red", marginRight: "5px" }}
                          ></CreateIcon>
                          Region
                        </p>
                      </div>
                      <FormControl fullWidth error={Boolean(errors.role)}>
                        <InputLabel id="demo-simple-select-label">
                          Choose Region
                        </InputLabel>
                        <Select
                          label="Choose Region"
                          name="region"
                          value={region}
                          onChange={handleChangeRegion}
                        >
                          {regionMenuItems.map((option) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>{errors.role}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="interviewer-form-option-title">
                        <FileCopyIcon style={{ color: "red" }}></FileCopyIcon>
                        Status
                      </div>
                      <FormControl fullWidth>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={status}
                          onChange={handleChangeStatus}
                          sx={{ position: "flex", flexDirection: "row" }}
                        >
                          <FormControlLabel
                            value="Certified"
                            control={<Radio />}
                            label={
                              <Typography
                                variant="body2"
                                style={{ fontSize: "14px" }}
                              >
                                Certified
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="Certified with Exception"
                            control={<Radio />}
                            label={
                              <Typography
                                variant="body2"
                                style={{ fontSize: "14px" }}
                              >
                                Certified with Exception
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                      <TextField
                        label="Remarks"
                        value={remark}
                        onChange={handleChangeRemarks}
                        placeholder="Remarks"
                        variant="outlined"
                        name="remarks"
                        sx={{ width: "85%" }}
                        disabled={ifStatusChanged}
                      />
                      <Tooltip title="View Remarks">
                        <GradingIcon
                          onClick={() => {
                            setRemarksDisplay(true);
                          }}
                          style={{
                            width: "10%",
                            height: "35%",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="candidate-form-option-title">
                        <p>
                          <CreateIcon
                            style={{ color: "red", marginRight: "5px" }}
                          ></CreateIcon>
                          Active
                        </p>
                      </div>
                      <FormControl fullWidth>
                        <ToggleButtonGroup
                          color="primary"
                          value={active}
                          exclusive
                          onChange={handleChangeToggle}
                          aria-label="Platform"
                        >
                          <ToggleButton
                            style={{ marginTop: "9.5px" }}
                            value="true"
                          >
                            True
                          </ToggleButton>
                          <ToggleButton
                            style={{ marginTop: "9.5px" }}
                            value="false"
                          >
                            False
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="candidate-form-option-title">
                        <p>
                          <CreateIcon
                            style={{ color: "red", marginRight: "5px" }}
                          ></CreateIcon>
                          Dates
                        </p>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {data
                            .filter((doc) => doc.username === username)
                            .map((doc) => {
                              const formatDate = (dateString) => {
                                if (!dateString) return "N/A";
                                const date = new Date(dateString);
                                const options = {
                                  day: 'numeric',
                                  month: 'short',
                                  year: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  second: 'numeric',
                                  hour12: true,
                                };
                                return date.toLocaleString('en-GB', options);
                              };

                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: "1",
                                    margin: "8px",
                                    border: "1px solid #ccc", 
                                    padding: "10px", 
                                  }}
                                >
                                  <div style={{ marginBottom: "8px" }}>
                                    <strong>Onboarding Date:</strong> {formatDate(doc.onboardingDate)}
                                  </div>
                                  <div style={{ marginBottom: "8px" }}>
                                    <strong>Empaneled Date:</strong> {formatDate(doc.empaneledDate)}
                                  </div>
                                  <div>
                                    <strong>Disempaneled Date:</strong> {formatDate(doc.disempaneledDate)}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    rowSpacing={0.5}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={6}>
                      <BlackButton
                        sx={{
                          width: "75%",
                          backgroundColor: "black",
                          left: "12%",
                        }}
                        onClick={handleCancel}
                      >
                        Cancel
                      </BlackButton>
                    </Grid>
                    <Grid item xs={6}>
                      <BlackButton
                        sx={{
                          width: "75%",
                          backgroundColor: "#1666ff",
                          alignItems: "center",
                          justifyContent: "center",
                          left: "12%",
                        }}
                        type="submit"
                      >
                        Submit
                      </BlackButton>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </div>
          </div>
        </div>
      )}
      <div>
        <RemarkModal
          showRemarks={showRemarks}
          setRemarksDisplay={setRemarksDisplay}
          remarks={allRemarks}
          status={status}
        />
      </div>
    </div>
  );
}
export default UpdateInterviewerPage;
