import { useAuthenticator } from '@aws-amplify/ui-react';
import React from 'react';
import '../styles/CreateForm.css';
import FormRow from "./FormRow";
import { useState,useEffect } from 'react';
import { useSelector } from "react-redux"
import axios from "axios";
import Loader from "./Loader"
import TemplateSubmit from './TemplateSubmit';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { proxy } from '../proxy';
function Form() {
    const { user} = useAuthenticator((context) => [context.user]);
    const {templateid}=useParams();
    const [data,setData]=useState([]);
    const [isLoading,setisLoading]=useState(true);
    const template=useSelector((state)=>{
        return state.template.template
    })
    const templateName=useSelector((state)=>{
        return state.template.templateName
    })

    const templateDescription=useSelector((state)=>{
      return state.template.templateDescription
  }) 
    // console.log(templateName)    
    const [roleData,setRoleData]=useState([]);

    useEffect(() => {
        axios.post(`${proxy}/api/template`,{"data":template},{
            withCredentials: true,
            headers: {
              Accept: "application/json",
              'Authorization':user.signInUserSession.idToken.jwtToken,
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {
                setisLoading(false);
              // console.log("result form",res.data.result);
              setData(res.data.result)
              // console.log("data form",data)
          }).catch((error)=>{
            setisLoading(false);
            alert("error")
          });
           // eslint-disable-next-line
      }, []);
      useEffect(() => {
        axios.get(`${proxy}/api/role`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              'Authorization':user.signInUserSession.idToken.jwtToken,
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {
              
              setRoleData(res.data.result);
          }).catch((error)=>{
            console.log(error)
          });


      }, []);
      const rows=data.map((rowdata,index) => {
        //   console.log("rowdata",rowdata)
                return(
                    <FormRow key={index} index={index} data={rowdata} roleData={roleData}/>
                )
        })
  
    return(
      <div style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",gap:"5px"}}>
        <div className="block-buttons-div" style={{width:"80%",textAlign:"center",justifyContent:"center",height:"40px"}}>
            <h2>Review and Submit</h2>
        </div>
        <div style={{textAlign:"center",width:"100%",display:"flex",flexDirection:"column",margin:"auto"}}>
          <h1>{templateName==="" ? "Not Selected":templateName}</h1>
          <h3>{templateDescription==="" ? "Not Selected":templateDescription}</h3>
        </div>
        {isLoading && <Loader/>}

        <div className='form-wrapper'>
            {!isLoading && 
            <div className='createform' style={{margin:"auto"}}>
              <div style={{display:"flex",flexDirection:"Column",gap:"10px"}}>
                {rows}
              </div>
            </div>
            }
        </div>
        <TemplateSubmit style={{margin:"auto"}} templateMongoId={templateid}/>
      </div>


    )
  }
  export default Form;