import {createSlice,createAsyncThunk } from "@reduxjs/toolkit"


let initialState=localStorage.getItem('myString') || "CnD";
// if (!initialState) {
//     initialState = "CnD";
//   }
const profileSlice=createSlice({
    name:'profile',
    initialState,
    reducers:{
        setProfile:(state,action)=>{
            console.log("Payload", action.payload)
            return action.payload;
        }
    }
})
export const {setProfile}=profileSlice.actions
export default profileSlice.reducer;